// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Material LIST
export const Materiallist = createAsyncThunk('MaterialMaster/Materiallist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/master/material/materialListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/master/material/materialList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Materiallist: response1.data.body && response1.data.body.MaterialArray ? response1.data.body.MaterialArray : [],
          MaterialTotal: response1.data.body && response1.data.body.MaterialTotal ? response1.data.body.MaterialTotal : 0
          
        }
      } else {
        return {
          Materiallist: response1.data.body ? response1.data.body.MaterialArray : [],
          MaterialTotal: response1.data.body ? response1.data.body.MaterialTotal : 0
        }
      }
    } else {
      return {
        Materiallist: response.data.body ? response.data.body.MaterialArray : [],
        MaterialTotal: response.data.body ? response.data.body.MaterialTotal : 0
      }
    }
  } catch (error) {
    return {
      Materiallist: [],
      MaterialTotal: 0
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('MaterialMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/material/saveMaterialJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/material/saveMaterial`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message,
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      MaterialId:response1.data.body.material_id,
      MaterialName:response1.data.body.material_name
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later',
      MaterialId:0,
      MaterialName:''
    }    
  }
})

//saveData Api
export const deleteData = createAsyncThunk('MaterialMaster/deleteData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/material/deleteMaterialJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/material/deleteMaterial`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})
export const MaterialMasterSlice = createSlice({
  name: 'MaterialMaster',
  initialState: {
    params: {},
    Materiallist:[],
    MaterialTotal: 0,
    statusFlag:0,
    message: '',
    MaterialId:0,
    MaterialName:''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.MaterialId = action.payload.MaterialId
     state.MaterialName = action.payload.MaterialName
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Materiallist.fulfilled, (state, action) => {
      state.Materiallist = action.payload.Materiallist
      state.MaterialTotal = action.payload.MaterialTotal
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = MaterialMasterSlice.actions
export default MaterialMasterSlice.reducer
