// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST
export const commonData = createAsyncThunk('EmbroiderSalaryProcess/commonData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/fetchCommonDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/fetchCommonData`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EmbroiderArray: response1.data.body.EmbroiderArray,
        allDepartmentArray:response1.data.body.allDepartmentArray
      }
     } else {
      return {
        params,
        EmbroiderArray: [],
        allDepartmentArray: []
      }
     } 
   }
  } catch (error) {
    return {
      params,
      EmbroiderArray: [],
      allDepartmentArray: []
    }
  } 
})


// Embroider LIST
export const Embroiderlist = createAsyncThunk('EmbroiderSalaryProcess/Embroiderlist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/ViewJobEmbroiderJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/ViewJobEmbroider`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        Embroiderlist: response1.data.body.Embroiderlist,
        TotalPieces: response1.data.body.TotalPieces,
        TotalAmount: response1.data.body.TotalAmount,
        TotalCount: response1.data.body.TotalCount
      }
     } else {
      return {
        params,
        Embroiderlist: [],
        TotalPieces: 0,
        TotalAmount: 0,
        TotalCount:0
      }
     } 
    } else {
      return {
        params,
        Embroiderlist: [],
        TotalPieces: 0,
        TotalAmount: 0,
        TotalCount:0
      }
    } 
  }  catch (error) {
    return {
      params,
      Embroiderlist: [],
      TotalPieces: 0,
      TotalAmount: 0,
      TotalCount:0
    }
  } 
})

// SalaryProcess LIST
export const SalaryProcesslist = createAsyncThunk('EmbroiderSalaryProcess/SalaryProcesslist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/jobEmbroiderProcessListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/jobEmbroiderProcessList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        ProcessList: response1.data.body.ProcessArray,
        CompanyList:response1.data.body.CompanyArray,
        TotalProcessList:response1.data.body.Total_Process
      }
     } else {
      return {
        ProcessList: [],
        CompanyList:[],
        TotalProcessList:0
      }
     } 
    }
  } catch (error) {
    return {
      ProcessList: [],
      CompanyList:[],
      TotalProcessList:0
    }
  } 
})

//saveData Api
export const saveData = createAsyncThunk('EmbroiderSalaryProcess/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/saveJobEmbroiderProcessJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/saveJobEmbroiderProcess`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }    
  }
})

// SalaryProcess Edit
export const editData = createAsyncThunk('EmbroiderSalaryProcess/editData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/editProcessJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/editProcess`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EditEmbroiderlist: response1.data.body.Embroiderlist
      }
     } else {
      return {
        params,
        EditEmbroiderlist: []
      }
     } 
    }
  } catch (error) {
    return {
      EditEmbroiderlist: []
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('EmbroiderSalaryProcess/deleteData', async params => { 
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/deleteProcessJwt`, params)  
    if (response && response.data && response.data.body && response.data.body.token) { 
      params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/deleteProcess`, params)
     if (response1 && response1.data && response1.data.body) { 
      return { 
        statusFlag:response1.data.body.statusFlag, 
        message:response1.data.body.message
      }
     }
    } else { 
      return {  
        statusFlag:response.data.statusFlag,
        message:response.data.message
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }    
  }
})

// PayslipList search
export const PayslipList = createAsyncThunk('EmbroiderSalaryProcess/PayslipList', async params => {
   try {
  const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/payslipListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/process/payslipList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        PayslipList: response1.data.body.PayslipArray
      }
    } else {
      return {
        PayslipList: []
      }
    }
  } else {
    return {
      PayslipList: []
    }
  }
} catch (error) {
  return {
    PayslipList: []
  }    
}
})

export const EmbroiderSalaryProcessSlice = createSlice({
  name: 'EmbroiderSalaryProcess',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Embroiderlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    EmbroiderArray:[],
    allDepartmentArray:[],
    ProcessList:[],
    TotalCount:0,
    TotalAmount:0.00,
    TotalPieces:0,
    EditTotalCount:0,
    EditTotalAmount:0.00,
    EditTotalPieces:0,
    EditEmbroiderlist:[],
    CompanyList:[],
    PayslipList:[],
    TotalProcessList: 0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.Embroiderlist = action.payload.Embroiderlist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.PayslipList = action.payload.PayslipList
      state.EditEmbroiderlist = action.payload.EditEmbroiderlist
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(PayslipList.fulfilled, (state, action) => {
      state.PayslipList = action.payload.PayslipList
    })
    .addCase(SalaryProcesslist.fulfilled, (state, action) => {
      state.ProcessList = action.payload.ProcessList
      state.CompanyList = action.payload.CompanyList
      state.params = action.payload.params
      state.TotalProcessList = action.payload.TotalProcessList 
    })
    .addCase(Embroiderlist.fulfilled, (state, action) => {
      state.Embroiderlist = action.payload.Embroiderlist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.params = action.payload.params
    })
    .addCase(editData.fulfilled, (state, action) => {
      state.EditEmbroiderlist = action.payload.EditEmbroiderlist
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.EmbroiderArray = action.payload.EmbroiderArray
      state.allDepartmentArray = action.payload.allDepartmentArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = EmbroiderSalaryProcessSlice.actions
export default EmbroiderSalaryProcessSlice.reducer
