// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('CustomerMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      StateArray: response1.data.body.StateArray,
      StatusList: response1.data.body.StatusArray,
      AgentList: response1.data.body.AgentArray,
      CustomerArray: response1.data.body.customerList
    }
   } else {
   } 
}
})

// CUstomerMaster LIST
// export const getCustomers = createAsyncThunk('CustomerMaster/getCustomers', async (params) => {
//   const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomersJwt`, params)
//   if (response && response.data && response.data.body && response.data.body.token) {
//     params = {jwtToken:response.data.body.token}
//     const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomers`, params)
//     if (response1 && response1.data && response1.data.body) {
//     return {
//       customerList: response1.data.body.customerList,
//       companyArray: response1.data.body.Company_Array
//     }
//    } else {
//    } 
// }
// })
export const getCustomers = createAsyncThunk('CustomerMaster/getCustomers', async params => {
  try {
  const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomersJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomers`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      customerList:response1.data.body && response1.data.body.customerList ? response1.data.body.customerList : [],
      companyArray:response1.data.body && response1.data.body.Company_Array ? response1.data.body.Company_Array : [],
      TotalCustomer:response1.data.body.TotalCustomer
    }
   } else {
    return {
      customerList:response1.data.body ? response1.data.body.customerList : [],
      companyArray:response1.data.body ? response1.data.body.Company_Array : [],
      TotalCustomer:response1.data.body ? response1.data.body.TotalCustomer : 0
    }
   }
  } else {
    return {
      customerList:response.data.body ? response.data.body.customerList : [],
      companyArray:response.data.body ? response.data.body.Company_Array : [],
      TotalCustomer:response.data.body ? response.data.body.TotalCustomer : 0
    }
   }
  } catch (error) {
    return {
      customerList: [],
      companyArray: [],
      TotalCustomer: 0
    }    
  }
})


// CUstomerMaster LIST
export const getCustomerDetails = createAsyncThunk('CustomerMaster/getCustomerDetails', async (params) => {
  const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomerDetailsJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/getCustomerDetails`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      customerDetails: response1.data.body.customerDetails
    }
   } else {
   } 
}
})


//saveData Api
export const saveData = createAsyncThunk('CustomerMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/saveCustomerJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/saveCustomer`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message,
      CustomerId:response1.data.body.customer_id,
      CustomerName:response1.data.body.customer_name
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
      CustomerId:response1.data.body ? response1.data.body.customer_id : 0,
      CustomerName:response1.data.body ? response1.data.body.customer_name : ''
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      CustomerId:response.data.body ? response.data.body.customer_id : 0,
      CustomerName:response.data.body ? response.data.body.customer_name : ''
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later',
      CustomerId: 0,
      CustomerName: ''
    }    
  }
})

//updateCustomerJwt Api
export const updateCustomer = createAsyncThunk('CustomerMaster/updateCustomer', async params => {
  try {
  const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/updateCustomerJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/updateCustomer`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('CustomerMaster/deleteCustomer', async params => { 
  const response = await axios.post(`${api.api_url}/orderManagement/customerManagement/deleteCustomerJwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/orderManagement/customerManagement/deleteCustomer`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const CustomerMasterSlice = createSlice({
  name: 'CustomerMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    ColorMasterlist:[],
    statuslist:[],
    StateArray:[],
    statusFlag:0,
    statuscode:0,
    customerList: [],
    TotalCustomer: 0,
    companyArray: [],
    customerDetails: '',
    StatusList: [],
    CustomerId:'',
    AgentList:[],
    CustomerArray:[],
    CustomerName: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.CustomerId = action.payload.CustomerId
      state.CustomerName = action.payload.CustomerName      
    })
    .addCase(updateCustomer.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(getCustomers.fulfilled, (state, action) => {
      state.customerList = action.payload.customerList
      state.companyArray = action.payload.companyArray   
      state.TotalCustomer = action.payload.TotalCustomer   
    })
    .addCase(getCustomerDetails.fulfilled, (state, action) => {
      state.customerDetails = action.payload.customerDetails
    })
    
    .addCase(commonData.fulfilled, (state, action) => {
      state.StateArray = action.payload.StateArray
      state.StatusList = action.payload.StatusList
      state.AgentList = action.payload.AgentList
      state.params = action.payload.params
      state.CustomerArray = action.payload.CustomerArray
    })
  }
})
export const {
  handleStatusFlag
} = CustomerMasterSlice.actions
export default CustomerMasterSlice.reducer
