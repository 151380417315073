// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../../utility/constants'


// ItemWiseOrder LIST
export const ItemWiseOrderReportlist = createAsyncThunk('ItemWiseOrder/ItemWiseOrderReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/itemWiseOrderListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/itemWiseOrderList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {
        params,
        ItemWiseOrderlist: response1.data.body.Itemwiselist,
        ItemWiseTotalCount: response1.data.body.ItemwiseCount,
        Designlist: response1.data.body.Designlist,
        DesignCount: response1.data.body.DesignCount,
        Itemlist: response1.data.body.Itemlist,
        ItemCount: response1.data.body.ItemCount,
        item_data_qty_result: response1.data.body.item_data_qty_result
      }
     } else {
     } 
  }
})
  // Master List
export const MasterReportlist = createAsyncThunk('ItemWiseOrder/MasterReportlist', async (params) => {
  const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
    if (response1 && response1.data && response1.data.body) { 
    return { 
      Customer_Lists: response1.data.body.Customer_Lists,
      Design_Lists: response1.data.body.Design_Lists,
      Color_Lists: response1.data.body.Color_Lists,
      Size_Lists: response1.data.body.Size_Lists
    }
   } else {
   } 
}
})

// ItemWiseOrder LIST
export const ExcelItemWiseOrderList = createAsyncThunk('ItemWiseOrder/ExcelItemWiseOrderList', async (params) => {
  const response = await axios.post(`${api.api_url}/orderreport/excelItemWiseOrderListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderreport/excelItemWiseOrderList`, params)
    if (response1 && response1.data && response1.data.body) { 
    return {
      params,
      Excel_Itemwiselist:response1.data.body.ExcelItemwiselist,
      CompanyList:response1.data.body.CompanyArray
    }
   } else {
    return {
      Excel_Itemwiselist:[],
      CompanyList:[]
    }
   } 
}
})

export const ItemWiseOrderReportSlice = createSlice({
  name: 'ItemWiseOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    ItemWiseOrderlist: [],
    ItemWiseTotalCount: 0,
    Designlist: [],
    DesignCount: 0,
    Itemlist: [],
    ItemCount:0,
    statuslist:[],
    statusFlag:0,
    statuscode: 0,
    Customer_Lists: [],
    Design_Lists: [],
    Color_Lists: [],
    Size_Lists: [],
    item_data_qty_result: [],
    Excel_Itemwiselist:[],
    CompanyList:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state) => {
      state.Excel_Itemwiselist = []
    }
  },
  extraReducers: builder => {
    builder.addCase(ItemWiseOrderReportlist.fulfilled, (state, action) => {
      state.ItemWiseOrderlist = action.payload.ItemWiseOrderlist
      state.ItemWiseTotalCount = action.payload.ItemWiseTotalCount
      state.Designlist = action.payload.Designlist
      state.DesignCount = action.payload.DesignCount
      state.Itemlist = action.payload.Itemlist
      state.ItemCount = action.payload.ItemCount
      state.params = action.payload.params 
      state.item_data_qty_result = action.payload.item_data_qty_result
    })
    .addCase(MasterReportlist.fulfilled, (state, action) => { 
      state.Customer_Lists = action.payload.Customer_Lists
      state.Design_Lists = action.payload.Design_Lists
      state.Color_Lists = action.payload.Color_Lists
      state.Size_Lists = action.payload.Size_Lists 
    })
    .addCase(ExcelItemWiseOrderList.fulfilled, (state, action) => { 
      state.Excel_Itemwiselist = action.payload.Excel_Itemwiselist
      state.CompanyList = action.payload.CompanyList
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = ItemWiseOrderReportSlice.actions
export default ItemWiseOrderReportSlice.reducer
