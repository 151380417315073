// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST
export const commonData = createAsyncThunk('PendingJobReport/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      alljobtypeArray: response1.data.body.alljobtypeArray,
      allitemArray:response1.data.body.allitemArray
    }
   } else {
   } 
}
})

// PendingJobReport LIST
export const PendingJobReportlist = createAsyncThunk('PendingJobReport/PendingJobReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/report/pendingJobReportListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/report/pendingJobReportList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        PendingJobReportlist: response1.data.body.PendingJobArray
      }
     } else {
     } 
  }
  })

  // Edit JobCard LIST
export const editData = createAsyncThunk('PendingJobReport/editData', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcard/editJobcardjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/jobcard/editJobcard`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EditJobcardArray: response1.data.body.EditJobcardArray
    }
   } else {
   } 
}
})

// Generate Jobno
export const generateJobno = createAsyncThunk('PendingJobReport/generateJobno', async (params) => {
  const response = await axios.post(`${api.api_url}/production/jobcard/generateJobnojwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/jobcard/generateJobno`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      JobNo: response1.data.body.JobNo
    }
   } else {
   } 
}
})

// Machine Wise Employee search
export const onchangemachineemployeeData = createAsyncThunk('PendingJobReport/onchangemachineemployeeData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineEmployeeData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        MachineEmployeeList: response1.data.body.MachineEmployeeList
      }
    } else {
    }
  }
}
)

//saveTransferData Api
export const saveTransferData = createAsyncThunk('PendingJobReport/saveTransferData', async params => {
  const response = await axios.post(`${api.api_url}/production/jobcard/saveTransferJobJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/jobcard/saveTransferJob`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   } 
  } else {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   }
})

// Machine search
export const onchangemachineData = createAsyncThunk('PendingJobReport/onchangemachineData', async params => {
  const response = await axios.post(`${api.api_url}/common/onchangeMachineDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/common/onchangeMachineData`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        ListMachine: response1.data.body.MachineList
      }
    } else {
    }
  }
}
)

export const PendingJobReportSlice = createSlice({
  name: 'PendingJobReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    PendingJobReportlist:[],
    statuslist:[],
    alljobtypeArray:[],
    allitemArray:[],
    statusFlag:0,
    statuscode:0,
    EditJobcardArray:[],
    JobNo:0,
    MachineEmployeeList:[],
    ListMachine:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state, action) => {
      state.EditJobcardArray = action.payload.EditJobcardArray
      state.MachineEmployeeList = action.payload.MachineEmployeeList
      state.ListMachine = action.payload.ListMachine
    }
  },
  extraReducers: builder => {
    builder.addCase(PendingJobReportlist.fulfilled, (state, action) => {
      state.PendingJobReportlist = action.payload.PendingJobReportlist
      state.params = action.payload.params
    })
    .addCase(editData.fulfilled, (state, action) => {
      state.EditJobcardArray = action.payload.EditJobcardArray
      state.params = action.payload.params
    })
    .addCase(generateJobno.fulfilled, (state, action) => {
      state.JobNo = action.payload.JobNo
    })
    .addCase(saveTransferData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(onchangemachineData.fulfilled, (state, action) => {
      state.ListMachine = action.payload.ListMachine
    })
    .addCase(onchangemachineemployeeData.fulfilled, (state, action) => {
      state.MachineEmployeeList = action.payload.MachineEmployeeList
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.alljobtypeArray = action.payload.alljobtypeArray
      state.allitemArray = action.payload.allitemArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = PendingJobReportSlice.actions
export default PendingJobReportSlice.reducer
