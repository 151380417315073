// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

export const getGRFilterList = createAsyncThunk('GoodsReturnData/getGRFilterListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getGRFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getGRFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Customer_List: response1.data.body && response1.data.body.CustomerList ? response1.data.body.CustomerList : [],
          Item_List: response1.data.body && response1.data.body.ItemList ? response1.data.body.ItemList : [],
          Filter_Message: response1.data.body && response1.data.body.ItemList ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      } else {
        return {
          Customer_List: response1.data.body ? response1.data.body.CustomerList : [],
          Item_List: response1.data.body ? response1.data.body.ItemList : [],
          Filter_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        Customer_List: response.data.body ? response.data.body.CustomerList : [],
        Item_List: response.data.body ? response.data.body.ItemList : [],
        Filter_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      Filter_Message: 'Server not reachable.Please try again later',
      Customer_List: [],
      Item_List: []
    }
  }
})
export const getGRCustomerList = createAsyncThunk('GoodsReturnData/getGRCustomerListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getGRCustomerListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getGRCustomerList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          GRCustomerList: response1.data.body && response1.data.body.dispatchedCustomerList ? response1.data.body.dispatchedCustomerList : [],
          Customer_Message: response1.data.body && response1.data.body.dispatchedCustomerList ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      } else {
        return {
          GRCustomerList: response1.data.body ? response1.data.body.dispatchedCustomerList : [],
          Customer_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        GRCustomerList: response.data.body ? response.data.body.dispatchedCustomerList : [],
        Customer_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      Customer_Message: 'Server not reachable.Please try again later',
      GRCustomerList: []
    }
  }
})

export const GetGRItemList = createAsyncThunk('GoodsReturnData/getGRItemListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getGRItemListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getGRItemList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          GRItemList: response1.data.body && response1.data.body.dispatchedItemList ? response1.data.body.dispatchedItemList : [],
          Item_Message: response1.data.body && response1.data.body.dispatchedItemList ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      } else {
        return {
          GRItemList: response1.data.body ? response1.data.body.dispatchedItemList : [],
          Item_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        GRItemList: response.data.body ? response.data.body.dispatchedItemList : [],
        Item_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      Item_Message: 'Server not reachable.Please try again later',
      GRItemList: []
    }
  }
})

export const GetDispatchedItemList = createAsyncThunk('GoodsReturnData/getDispatchListBasedOnItemCustomerJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getDispatchListBasedOnItemCustomerJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getDispatchListBasedOnItemCustomer`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          DispatchedItemList: response1.data.body && response1.data.body.dispatchedArray ? response1.data.body.dispatchedArray : [],
          DispatchedItem_Message: response1.data.body && response1.data.body.dispatchedArray ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      } else {
        return {
          DispatchedItemList: response1.data.body ? response1.data.body.dispatchedArray : [],
          DispatchedItem_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        DispatchedItemList: response.data.body ? response.data.body.dispatchedArray : [],
        DispatchedItem_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      DispatchedItem_Message: 'Server not reachable.Please try again later',
      DispatchedItemList: []
    }
  }
})

// Save ItemWise Dispatch
export const saveGoodsReturn = createAsyncThunk('GoodsReturnData/saveGoodsReturn', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/saveGoodsReturnJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/saveGoodsReturn`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

export const getGRList = createAsyncThunk('GoodsReturnData/getGRListJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getGRListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getGRList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          GoodsReturnList: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.goods_return_array : [],
          GRListItemMessage: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.Message : 'Server not reachable.Please try again later',
          GoodsReturnTotal: response1.data.body && response1.data.body.goods_return_total ? response1.data.body.goods_return_total : 0,
          overallTotalsets: response1.data.body && response1.data.body.overallTotal_sets ? response1.data.body.overallTotal_sets : 0,
          overallTotalPieces: response1.data.body && response1.data.body.overallTotal_Pieces ? response1.data.body.overallTotal_Pieces : 0,
          GoodsReturnWidget: response1.data.body && response1.data.body.goodsReturnWidget ? response1.data.body.goodsReturnWidget : []
        }
      } else {
        return {
          GoodsReturnList: response1.data.body ? response1.data.body.goods_return_array : [],
          GRListItemMessage: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          GoodsReturnTotal: response1.data.body ? response1.data.body.goods_return_total : 0,
          overallTotalsets: response1.data.body ? response1.data.body.overallTotal_sets : 0,
          overallTotalPieces: response1.data.body ? response1.data.body.overallTotal_Pieces : 0,
          GoodsReturnWidget: response1.data.body ? response1.data.body.goodsReturnWidget : []
        }
      }
    } else {
      return {
        GoodsReturnList: [],
        GRListItemMessage: 'Server not reachable.Please try again later',
        GoodsReturnTotal: 0,
        overallTotalsets: 0,
        overallTotalPieces: 0,
        GoodsReturnWidget: []
      }
    }
  } catch (error) {
    return {
      GoodsReturnList: [],
      GRListItemMessage: 'Server not reachable.Please try again later',
      GoodsReturnTotal: 0,
      overallTotalsets: 0,
      overallTotalPieces: 0,
      GoodsReturnWidget: []
    }
  }
})

///
export const PrintGRList = createAsyncThunk('GoodsReturnData/printGRlistJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/getGRListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/getGRList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          Print_GoodsReturnList: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.goods_return_array : [],
          Print_GRListItemMessage: response1.data.body && response1.data.body.goods_return_array ? response1.data.body.Message : 'Server not reachable.Please try again later',
          Print_GoodsReturnCompany: response1.data.body && response1.data.body.Company_Array ? response1.data.body.Company_Array : [],
          Print_GoodsReturnResult: 'true',
          Print_GoodsReturnWidget: response1.data.body && response1.data.body.goodsReturnWidget ? response1.data.body.goodsReturnWidget : []
        }
      } else {
        return {
          Print_GoodsReturnList: response1.data.body ? response1.data.body.goods_return_array : [],
          Print_GRListItemMessage: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          Print_GoodsReturnCompany: response1.data.body ? response1.data.body.Company_Array : [],
          Print_GoodsReturnResult: 'false',
          Print_GoodsReturnWidget: response1.data.body ? response1.data.body.goodsReturnWidget : []
        }
      }
    } else {
      return {
        Print_GoodsReturnList: [],
        Print_GRListItemMessage: 'Server not reachable.Please try again later',
        Print_GoodsReturnCompany: [],
        Print_GoodsReturnResult: 'false',
        Print_GoodsReturnWidget: []
      }
    }
  } catch (error) {
    return {
      Print_GoodsReturnList: [],
      Print_GRListItemMessage: 'Server not reachable.Please try again later',
      Print_GoodsReturnCompany: [],
      Print_GoodsReturnResult: 'false',
      Print_GoodsReturnWidget: []
    }
  }
})

export const cancelGoodsReturn = createAsyncThunk('GoodsReturnData/cancelGoodsReturnJwt', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/goodsReturn/cancelGoodsReturnJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/goodsReturn/cancelGoodsReturn`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          GR_Delete_Message: response1.data.body.message,
          GR_Delete_StatusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2
        }
      }  else {
        return {
          GR_Delete_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later',
          GR_Delete_StatusFlag: response1.data.body ? response1.data.body.GR_Delete_statusFlag : 2
        }
      }
    }  else {
      return {
        GR_Delete_Message: 'Server not reachable.Please try again later',
        GR_Delete_StatusFlag: 2
      }
    }
  } catch (error) {
    return {
      GR_Delete_Message: 'Server not reachable.Please try again later',
      GR_Delete_StatusFlag: 2
    }
  }
  })

export const GoodsReturnData = createSlice({
  name: 'GoodsReturnData',
  initialState: {
    params: {},
    statusFlag: 0,
    message: '',
    GRCustomerList: [],
    Customer_Message: '',
    GRItemList: [],
    ItemList: [],
    Item_Message: '',
    DispatchedItemList: [],
    DispatchedItem_Message: '',
    GoodsReturnList: [],
    GRListItemMessage: '',
    GoodsReturnTotal: 0,
    overallTotalsets: 0,
    overallTotalPieces: 0,
    GoodsReturnWidget: [],
    Print_GoodsReturnList: [],
    Print_GRListItemMessage: '',
    Print_GoodsReturnCompany: [],
    Print_GoodsReturnResult: 'false',
    Print_GoodsReturnWidget: [],
    Filter_Message: '',
    Customer_List: [],
    Item_List: [],
    Customer_Lists: [],
    GR_Delete_Message: '',
    GR_Delete_StatusFlag: 0   
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag     
      state.message = action.payload.message      
      state.FG_Delete_message = action.payload.FG_Delete_message
      state.FG_Delete_statusFlag = action.payload.FG_Delete_statusFlag  
      state.GR_Delete_Message = action.payload.GR_Delete_Message
      state.GR_Delete_StatusFlag = action.payload.GR_Delete_StatusFlag  
    },

    clearstoreData: (state, action) => {
      state.GRCustomerList = action.payload.GRCustomerList
      state.Customer_Message = action.payload.Customer_Message
      state.GRItemList = action.payload.GRItemList
      state.Item_Message = action.payload.Item_Message
      state.DispatchedItemList = action.payload.DispatchedItemList
      state.DispatchedItem_Message = action.payload.DispatchedItem_Message
      state.GoodsReturnList = action.payload.GoodsReturnList
      state.GRListItemMessage = action.payload.GRListItemMessage
      state.GoodsReturnTotal = action.payload.GoodsReturnTotal
      state.overallTotalsets = action.payload.overallTotalsets
      state.overallTotalPieces = action.payload.overallTotalPieces
      state.GoodsReturnWidget = action.payload.GoodsReturnWidget
      state.Print_GoodsReturnList = action.payload.Print_GoodsReturnList
      state.Print_GRListItemMessage = action.payload.Print_GRListItemMessage
      state.Print_GoodsReturnCompany = action.payload.Print_GoodsReturnCompany
      state.Print_GoodsReturnResult = action.payload.Print_GoodsReturnResult
      state.Print_GoodsReturnWidget = action.payload.Print_GoodsReturnWidget
      state.Customer_List = action.payload.Customer_List
      state.Item_List = action.payload.Item_List
      state.Filter_Message = action.payload.Filter_Message    
    }
  },
  extraReducers: builder => {
    builder.addCase(getGRFilterList.fulfilled, (state, action) => {
      state.Customer_List = action.payload.Customer_List
      state.Item_List = action.payload.Item_List
      state.Filter_Message = action.payload.Filter_Message
    }).addCase(getGRCustomerList.fulfilled, (state, action) => {
      state.GRCustomerList = action.payload.GRCustomerList
      state.Customer_Message = action.payload.Customer_Message
    }).addCase(GetGRItemList.fulfilled, (state, action) => {
      state.GRItemList = action.payload.GRItemList
      state.Item_Message = action.payload.Item_Message
    }).addCase(GetDispatchedItemList.fulfilled, (state, action) => {
      state.DispatchedItemList = action.payload.DispatchedItemList
      state.DispatchedItem_Message = action.payload.DispatchedItem_Message
    }).addCase(saveGoodsReturn.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    }).addCase(getGRList.fulfilled, (state, action) => {
      state.GoodsReturnList = action.payload.GoodsReturnList
      state.GRListItemMessage = action.payload.GRListItemMessage
      state.GoodsReturnTotal = action.payload.GoodsReturnTotal
      state.overallTotalsets = action.payload.overallTotalsets
      state.overallTotalPieces = action.payload.overallTotalPieces
      state.GoodsReturnWidget = action.payload.GoodsReturnWidget
    }).addCase(PrintGRList.fulfilled, (state, action) => {
      state.Print_GoodsReturnList = action.payload.Print_GoodsReturnList
      state.Print_GRListItemMessage = action.payload.Print_GRListItemMessage
      state.Print_GoodsReturnCompany = action.payload.Print_GoodsReturnCompany
      state.Print_GoodsReturnResult = action.payload.Print_GoodsReturnResult
      state.Print_GoodsReturnWidget = action.payload.Print_GoodsReturnWidget
    }).addCase(cancelGoodsReturn.fulfilled, (state, action) => {
      state.GR_Delete_Message = action.payload.GR_Delete_Message
      state.GR_Delete_StatusFlag = action.payload.GR_Delete_StatusFlag
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = GoodsReturnData.actions
export default GoodsReturnData.reducer
