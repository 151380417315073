// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../../utility/constants'


// CurrentStockOrder LIST
export const CurrentStockOrderReportlist = createAsyncThunk('CurrentStockOrder/CurrentStockOrderReportlist', async (params) => { 
    const response = await axios.post(`${api.api_url}/orderreport/currentStockListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/currentStockList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {
        params,
        currentStockList: response1.data.body.CurrentStockList,
        CurrentStockCount: response1.data.body.CurrentStockCount
      }
     } else {
     } 
  }
  })

export const CurrentStockOrderReportSlice = createSlice({
  name: 'CurrentStockOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    currentStockList: [],
    CurrentStockCount:0,
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(CurrentStockOrderReportlist.fulfilled, (state, action) => {
      state.currentStockList = action.payload.currentStockList
      state.CurrentStockCount = action.payload.CurrentStockCount
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = CurrentStockOrderReportSlice.actions
export default CurrentStockOrderReportSlice.reducer
