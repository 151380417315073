// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


  export const getGRCustomerList = createAsyncThunk('CustomerWiseReport/getGRCustomerList', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/GetCustomerDetailsJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/report/GetCustomerDetails`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            CustomerList: response1.data.body && response1.data.body.Customer_Array ? response1.data.body.Customer_Array : [],
            Customer_Message: response1.data.body && response1.data.body.Customer_Array ? response1.data.body.Message : 'Server not reachable.Please try again later'
          }
        } else {
          return {
            CustomerList: [],
            Customer_Message: response1.data.body ? response1.data.body.Message : 'Server not reachable.Please try again later'
          }
        }
      } else {
        return {
          CustomerList: [],
          Customer_Message: response.data.body ? response.data.body.Message : 'Server not reachable.Please try again later'
        }
      }
    } catch (error) {
      return {
        Customer_Message: 'Server not reachable.Please try again later',
        CustomerList: []
      }
    }
  })

  export const customerRetailReport = createAsyncThunk('CustomerWiseReport/customerRetailReport', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/customerRetailReportJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
        // params = {jwtToken:response.data.body.token}
        const response1 = await axios.post(`${api.api_url}/report/customerRetailReport`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            TotalCancelledOrder: response1.data.body.CancelledOrder,
            TotalOrdersSets: response1.data.body.TotalOrders,
            TotalOrderCount: response1.data.body.TotalOrderCount,
            TotalHoldOrder: response1.data.body.HoldOrder,
            Pending_Order: response1.data.body.PendingOrderSet,
            TotalDispatch: response1.data.body.TotalDispatch,
            NoOfTotalPendingOrders: response1.data.body.TotalPendingOrders,
            NoOfTotalCancelledOrders: response1.data.body.TotalCancelledOrder,
            NoOfTotalHoldOrders: response1.data.body.TotalHoldOrder,
            NoOfTotalDispatchOrders: response1.data.body.TotalDispatchOrders,
            NoOfTotalCloseOrders: response1.data.body.TotalCloseOrders,
            TotalCloseOrder: response1.data.body.CloseOrder
          }
        } else {
          return {
            TotalCancelledOrder: [],
            TotalOrdersSets: [],
            TotalHoldOrder: [],
            Pending_Order: [],
            TotalDispatch: [],
            TotalOrderCount: 0,
            NoOfTotalPendingOrders: 0,
            NoOfTotalDispatchOrders: 0,
            NoOfTotalCancelledOrders: 0,
            NoOfTotalHoldOrders: 0,
            NoOfTotalCloseOrders: 0,
            TotalCloseOrder: []
          }
        }
      } else {
        return {
          TotalCancelledOrder: [],
          TotalOrdersSets: [],
          TotalHoldOrder: [],
          Pending_Order: [],
          TotalDispatch: [],
          TotalOrderCount: 0,
          NoOfTotalPendingOrders: 0,
          NoOfTotalDispatchOrders: 0,
          NoOfTotalCancelledOrders: 0,
          NoOfTotalHoldOrders: 0,
          NoOfTotalCloseOrders: 0,
          TotalCloseOrder: []
        }
      }
    } catch (error) {
      return {
        TotalCancelledOrder: [],
        TotalOrdersSets: [],
        TotalHoldOrder: [],
        Pending_Order: [],
        TotalDispatch: [],
        TotalOrderCount: 0,
        NoOfTotalPendingOrders: 0,
        NoOfTotalDispatchOrders: 0,
        NoOfTotalCancelledOrders: 0,
        NoOfTotalHoldOrders: 0,
        NoOfTotalCloseOrders: 0,
        TotalCloseOrder: []
      }
    }
  })

  export const customerRetailOrderReport = createAsyncThunk('CustomerWiseReport/customerRetailOrderReport', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/GetDetailedOrdersJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
        // params = {jwtToken:response.data.body.token}
        const response1 = await axios.post(`${api.api_url}/report/GetDetailedOrders`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            OrderList: response1.data.body.OrderList,
            overallTotal: response1.data.body.overallTotal,
            Catagorywise: response1.data.body.CatagorywiseList
          }
        } else {
          return {
            OrderList: [],
            overallTotal: 0,
            Catagorywise:[]
          }
        }
      } else {
        return {
          OrderList: [],
          overallTotal: 0,
          Catagorywise: []
        }
      }
    } catch (error) {
      return {
        OrderList: [],
        overallTotal: 0,
        Catagorywise: []
      }
    }
  })

  export const ExcelCustomerWiseDetailedlist = createAsyncThunk('CustomerWiseReport/ExcelCustomerWiseDetailedlist', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/GetDetailedOrdersJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
        // params = {jwtToken:response.data.body.token}
        const response1 = await axios.post(`${api.api_url}/report/GetDetailedOrders`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            ExcelTotalCancelledOrder: response1.data.body.CancelledOrder,
            ExcelTotalOrdersSets: response1.data.body.TotalOrders,
            ExcelExcelTotalOrderCount: response1.data.body.TotalOrderCount,
            ExcelTotalHoldOrder: response1.data.body.HoldOrder,
            ExcelPending_Order: response1.data.body.PendingOrderSet,
            ExcelTotalDispatch: response1.data.body.TotalDispatch,
            ExcelNoOfTotalPendingOrders: response1.data.body.TotalPendingOrders,
            ExcelNoOfTotalCancelledOrders: response1.data.body.TotalCancelledOrder,
            ExcelNoOfTotalHoldOrders: response1.data.body.TotalHoldOrder,
            ExcelNoOfTotalDispatchOrders: response1.data.body.TotalDispatchOrders,
            ExcelCancelledOrders : response1.data.body.CancelledOrders,
            ExcelHoldOrders : response1.data.body.HoldOrders,
            ExcelDispatchOrders : response1.data.body.DispatchOrders,
            ExcelPendingOrders : response1.data.body.PendingOrders, 
            company_details : response1.data.body.company_details,
            HoldCatagorys :response1.data.body.HoldCatagorys,
            CancelCatagorys :response1.data.body.CancelCatagorys,
            PendingCatagorys :response1.data.body.PendingCatagorys,
            DispatchCatagorys :response1.data.body.DispatchCatagorys,
            ExcelCloseOrders : response1.data.body.CloseOrders,
            ExcelTotalCloseOrder: response1.data.body.CloseOrder, 
            ExcelNoOfTotalCloseOrders: response1.data.body.TotalCloseOrder,
            CloseCatagorys :response1.data.body.CloseCatagorys,
            Excel_Result: 'true'
          }
        } else {
          return {
            ExcelTotalCancelledOrder: [],
            ExcelTotalOrdersSets: [],
            ExcelExcelTotalOrderCount: [],
            ExcelTotalHoldOrder: [],
            ExcelPending_Order: [],
            ExcelTotalDispatch: [],
            ExcelNoOfTotalPendingOrders: 0,
            ExcelNoOfTotalCancelledOrders: 0,
            ExcelNoOfTotalHoldOrders: 0,
            ExcelNoOfTotalDispatchOrders: 0,
            OrderList: [],
            overallTotal: 0,
            Catagorywise: [],
            ExcelCancelledOrders : [],
            ExcelHoldOrders : [],
            ExcelDispatchOrders : [],
            ExcelPendingOrders : [], 
            company_details : [],
            ExcelCatagoryWidget : [],
            HoldCatagorys :[],
            CancelCatagorys :[],
            PendingCatagorys :[],
            DispatchCatagorys :[],
            ExcelCloseOrders : [],
            ExcelTotalCloseOrder: [], 
            ExcelNoOfTotalCloseOrders: [],
            CloseCatagorys :[],
            Excel_Result: 'false'
          }
        }
      } else {
        return {
          ExcelTotalCancelledOrder: [],
          ExcelTotalOrdersSets: [],
          ExcelExcelTotalOrderCount: [],
          ExcelTotalHoldOrder: [],
          ExcelPending_Order: [],
          ExcelTotalDispatch: [],
          ExcelNoOfTotalPendingOrders: 0,
          ExcelNoOfTotalCancelledOrders: 0,
          ExcelNoOfTotalHoldOrders: 0,
          ExcelNoOfTotalDispatchOrders: 0,
          OrderList: [],
          overallTotal: 0,
          Catagorywise: [],
          ExcelCancelledOrders : [],
          ExcelHoldOrders : [],
          ExcelDispatchOrders : [],
          ExcelPendingOrders : [], 
          company_details : [],
          ExcelCatagoryWidget : [],
          HoldCatagorys :[],
          CancelCatagorys :[],
          PendingCatagorys :[],
          DispatchCatagorys :[],
          ExcelCloseOrders : [],
          ExcelTotalCloseOrder: [], 
          ExcelNoOfTotalCloseOrders: [],
          CloseCatagorys :[],
          Excel_Result: 'false'
        }
      }
    } catch (error) {
      return {
        ExcelTotalCancelledOrder: [],
        ExcelTotalOrdersSets: [],
        ExcelExcelTotalOrderCount: [],
        ExcelTotalHoldOrder: [],
        ExcelPending_Order: [],
        ExcelTotalDispatch: [],
        ExcelNoOfTotalPendingOrders: 0,
        ExcelNoOfTotalCancelledOrders: 0,
        ExcelNoOfTotalHoldOrders: 0,
        ExcelNoOfTotalDispatchOrders: 0,
        OrderList: [],
        overallTotal: 0,
        Catagorywise: [],
        ExcelCancelledOrders : [],
        ExcelHoldOrders : [],
        ExcelDispatchOrders : [],
        ExcelPendingOrders : [], 
        company_details : [],
        ExcelCatagoryWidget : [],
        HoldCatagorys :[],
        CancelCatagorys :[],
        PendingCatagorys :[],
        DispatchCatagorys :[],
        ExcelCloseOrders : [],
        ExcelTotalCloseOrder: [], 
        ExcelNoOfTotalCloseOrders: [],
        CloseCatagorys :[],
        Excel_Result: 'false'
      }
    }
  })

export const CustomerReportSlice = createSlice({
  name: 'CustomerWiseReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    CustomerList: [],
    statuslist:[],
    CustomerList:[],
    statusFlag:0,
    statuscode:0,
    TotalCancelledOrder: [],
    TotalOrdersSets: [],
    TotalHoldOrder: [],
    Pending_Order: 0,
    TotalDispatch: [],
    NoOfTotalPendingOrders: 0,
    NoOfTotalDispatchOrders: 0,
    NoOfTotalCancelledOrders: 0,
    NoOfTotalHoldOrders: 0,
    NoOfTotalCloseOrders: 0,
    TotalCloseOrder: [],
    Catagorywise: [],
    OrderList: [],
    overallTotal: 0,
    ExcelCancelledOrders: [],
    ExcelHoldOrders: [],
    ExcelDispatchOrders: [],
    ExcelPendingOrders: [],
    company_details: [],
    ExcelCatagoryWidget:[],
    HoldCatagorys :[],
    CancelCatagorys :[],
    PendingCatagorys :[],
    DispatchCatagorys :[],
    ExcelTotalCancelledOrder: [],
    ExcelTotalOrdersSets: [],
    ExcelExcelTotalOrderCount: [],
    ExcelTotalHoldOrder: [],
    ExcelPending_Order: [],
    ExcelTotalDispatch: [],
    ExcelNoOfTotalPendingOrders: 0,
    ExcelNoOfTotalCancelledOrders: 0,
    ExcelNoOfTotalHoldOrders: 0,
    ExcelNoOfTotalDispatchOrders: 0,
    ExcelNoOfTotalCloseOrders: 0,
    ExcelCloseOrders : [],
    ExcelTotalCloseOrder: [], 
    ExcelNoOfTotalCloseOrders: [],
    CloseCatagorys :[], 
    Excel_Result: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearStoreData: (state, action) => {
      state.TotalCancelledOrder = action.payload.TotalCancelledOrder
      state.TotalOrdersSets = action.payload.TotalOrdersSets
      state.TotalHoldOrder = action.payload.TotalHoldOrder
      state.Pending_Order = action.payload.Pending_Order
      state.TotalDispatch = action.payload.TotalDispatch
      state.TotalOrderCount = action.payload.TotalOrderCount
      state.NoOfTotalPendingOrders = action.payload.NoOfTotalPendingOrders
      state.NoOfTotalDispatchOrders = action.payload.NoOfTotalDispatchOrders
      state.NoOfTotalCancelledOrders = action.payload.NoOfTotalCancelledOrders
      state.NoOfTotalHoldOrders = action.payload.NoOfTotalHoldOrders
      state.NoOfTotalCloseOrders = action.payload.NoOfTotalCloseOrders  
      state.TotalCloseOrder = action.payload.TotalCloseOrder
    },
    clearstoreExcelData: (state, action) => {
      state.ExcelTotalCancelledOrder = action.payload.ExcelTotalCancelledOrder 
      state.ExcelTotalOrdersSets = action.payload.ExcelTotalOrdersSets 
      state.ExcelExcelTotalOrderCount = action.payload.ExcelExcelTotalOrderCount 
      state.ExcelTotalHoldOrder = action.payload.ExcelTotalHoldOrder 
      state.ExcelPending_Order = action.payload.ExcelPending_Order 
      state.ExcelTotalDispatch = action.payload.ExcelTotalDispatch 
      state.ExcelNoOfTotalPendingOrders = action.payload.ExcelNoOfTotalPendingOrders 
      state.ExcelNoOfTotalCancelledOrders = action.payload.ExcelNoOfTotalCancelledOrders 
      state.ExcelNoOfTotalHoldOrders = action.payload.ExcelNoOfTotalHoldOrders 
      state.ExcelNoOfTotalDispatchOrders = action.payload.ExcelNoOfTotalDispatchOrders 
      state.ExcelCancelledOrders = action.payload.ExcelCancelledOrders 
      state.ExcelHoldOrders = action.payload.ExcelHoldOrders 
      state.ExcelDispatchOrders = action.payload.ExcelDispatchOrders 
      state.ExcelPendingOrders = action.payload.ExcelPendingOrders 
      state.ExcelCatagoryWidget = action.payload.ExcelCatagoryWidget
      state.HoldCatagorys = action.payload.HoldCatagorys
      state.CancelCatagorys = action.payload.CancelCatagorys
      state.PendingCatagorys = action.payload.PendingCatagorys
      state.DispatchCatagorys = action.payload.DispatchCatagorys
      state.Excel_Result = action.payload.Excel_Result
      state.ExcelCloseOrders = action.payload.ExcelCloseOrders  
      state.ExcelTotalCloseOrder = action.payload.ExcelTotalCloseOrder
      state.ExcelNoOfTotalCloseOrders = action.payload.ExcelNoOfTotalCloseOrders
      state.CloseCatagorys = action.payload.CloseCatagorys
      
    }
  },
  extraReducers: builder => {
    builder.addCase(getGRCustomerList.fulfilled, (state, action) => { 
      state.CustomerList = action.payload.CustomerList
    }).addCase(customerRetailReport.fulfilled, (state, action) => { 
      state.TotalCancelledOrder = action.payload.TotalCancelledOrder
      state.TotalOrdersSets = action.payload.TotalOrdersSets
      state.TotalHoldOrder = action.payload.TotalHoldOrder
      state.Pending_Order = action.payload.Pending_Order
      state.TotalDispatch = action.payload.TotalDispatch
      state.NoOfTotalPendingOrders = action.payload.NoOfTotalPendingOrders
      state.NoOfTotalDispatchOrders = action.payload.NoOfTotalDispatchOrders  
      state.TotalOrderCount = action.payload.TotalOrderCount
      state.NoOfTotalCancelledOrders = action.payload.NoOfTotalCancelledOrders  
      state.NoOfTotalHoldOrders = action.payload.NoOfTotalHoldOrders  
      state.NoOfTotalCloseOrders = action.payload.NoOfTotalCloseOrders  
      state.TotalCloseOrder = action.payload.TotalCloseOrder
    }).addCase(customerRetailOrderReport.fulfilled, (state, action) => { 
      state.OrderList = action.payload.OrderList
      state.overallTotal = action.payload.overallTotal
      state.Catagorywise = action.payload.Catagorywise
    }).addCase(ExcelCustomerWiseDetailedlist.fulfilled, (state, action) => {
      state.ExcelTotalCancelledOrder = action.payload.ExcelTotalCancelledOrder 
      state.ExcelTotalOrdersSets = action.payload.ExcelTotalOrdersSets 
      state.ExcelExcelTotalOrderCount = action.payload.ExcelExcelTotalOrderCount 
      state.ExcelTotalHoldOrder = action.payload.ExcelTotalHoldOrder 
      state.ExcelPending_Order = action.payload.ExcelPending_Order 
      state.ExcelTotalDispatch = action.payload.ExcelTotalDispatch 
      state.ExcelNoOfTotalPendingOrders = action.payload.ExcelNoOfTotalPendingOrders 
      state.ExcelNoOfTotalCancelledOrders = action.payload.ExcelNoOfTotalCancelledOrders 
      state.ExcelNoOfTotalHoldOrders = action.payload.ExcelNoOfTotalHoldOrders 
      state.ExcelNoOfTotalDispatchOrders = action.payload.ExcelNoOfTotalDispatchOrders 
      state.ExcelCancelledOrders = action.payload.ExcelCancelledOrders 
      state.ExcelHoldOrders = action.payload.ExcelHoldOrders 
      state.ExcelDispatchOrders = action.payload.ExcelDispatchOrders 
      state.ExcelPendingOrders = action.payload.ExcelPendingOrders 
      state.company_details = action.payload.company_details
      state.HoldCatagorys = action.payload.HoldCatagorys
      state.CancelCatagorys = action.payload.CancelCatagorys
      state.PendingCatagorys = action.payload.PendingCatagorys
      state.DispatchCatagorys = action.payload.DispatchCatagorys
      state.Excel_Result = action.payload.Excel_Result
      state.ExcelCloseOrders = action.payload.ExcelCloseOrders  
      state.ExcelTotalCloseOrder = action.payload.ExcelTotalCloseOrder
      state.ExcelNoOfTotalCloseOrders = action.payload.ExcelNoOfTotalCloseOrders
      state.CloseCatagorys = action.payload.CloseCatagorys
    })
  }
})

export const {
  handleStatusFlag, clearStoreData, clearstoreExcelData
} = CustomerReportSlice.actions
export default CustomerReportSlice.reducer
