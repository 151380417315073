// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


// RateEmbroider LIST
export const RateEmbroiderlist = createAsyncThunk('RateEmbroider/RateEmbroiderlist', async (params) => {
  const response = await axios.post(`${api.api_url}/production/rateembroider/rateEmbroiderListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/rateembroider/rateEmbroiderList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      RateEmbroiderList: response1.data.body.RateEmbroiderArray,
      RateEmbroiderTotalList: response1.data.body.RateEmbroiderTotal
    }
   } else {
   } 
}
})
  // Master List
  export const MasterReportlist = createAsyncThunk('ItemWiseOrder/MasterReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {  
        Design_Embroider_Lists: response1.data.body.job_embroider_design_code 
      }
     } else {
     } 
  }
  })
//saveData Api
export const saveData = createAsyncThunk('RateEmbroider/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/production/rateembroider/saveRateEmbroiderJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/rateembroider/saveRateEmbroider`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   }  else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later'
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('RateEmbroider/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/production/rateembroider/deleteRateEmbroiderJwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/rateembroider/deleteRateEmbroider`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const RateEmbroiderSlice = createSlice({
  name: 'RateEmbroider',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    RateEmbroiderList:[],
    RateEmbroiderTotalList: 0,
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    Design_Embroider_Lists: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(RateEmbroiderlist.fulfilled, (state, action) => {
      state.RateEmbroiderList = action.payload.RateEmbroiderList
      state.RateEmbroiderTotalList = action.payload.RateEmbroiderTotalList      
      state.params = action.payload.params
    })
    .addCase(MasterReportlist.fulfilled, (state, action) => {  
      state.Design_Embroider_Lists = action.payload.Design_Embroider_Lists 
    })
  }
})
export const {
  handleStatusFlag
} = RateEmbroiderSlice.actions
export default RateEmbroiderSlice.reducer
