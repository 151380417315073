// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants' 

//  GET Common LIST
export const commonData = createAsyncThunk('ItemMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      ItemgroupArray: response1.data.body.ItemgroupArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('ItemMaster/saveData', async params => {
    const response = await axios.post(`${api.api_url}/master/item/saveItemjwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/master/item/saveItem`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     } 
    } else {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     }
  })

  
// Item LIST
export const itemlist = createAsyncThunk('ItemMaster/itemlist', async (params) => {
    const response = await axios.post(`${api.api_url}/master/item/itemListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/master/item/itemList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        itemlist: response1.data.body.ItemArray,
        SizeArray:response1.data.body.SizeArray
      }
     } else {
      return {
        itemlist:[],
        SizeArray:[]
      }
     } 
  }
  })

// Size LIST
export const Sizelist = createAsyncThunk('ItemMaster/Sizelist', async (params) => {
  const response = await axios.post(`${api.api_url}/master/item/sizeListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/master/item/sizeList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      EditSizeList:response1.data.body.EditSizeList,
      EditItemArray:response1.data.body.EditItemArray
    }
   } else {
   } 
}
})

  //DeleteData Api
   export const DeleteData = createAsyncThunk('ItemMaster/DeleteData', async params => {
    const response = await axios.post(`${api.api_url}/master/item/deleteItemjwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/master/item/deleteItem`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     } 
    } else {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     }
  })

export const ItemMasterSlice = createSlice({
    name: 'ItemMaster',
    initialState: {
      data: [],
      message:'',
      statuslist:[],
      params: {},
      statusFlag:0,
      ItemgroupArray:[],
      itemlist:[],
      SizeArray:[],
      EditSizeList:[],
      EditItemArray:[]
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload.statusFlag
      },
      clearstoreData: (state, action) => {
        state.EditSizeList = action.payload.EditSizeList
        state.EditItemArray = action.payload.EditItemArray
      }
    },
    extraReducers: builder => {
      builder.addCase(commonData.fulfilled, (state, action) => {
        state.ItemgroupArray = action.payload.ItemgroupArray
        state.params = action.payload.params
      })
      .addCase(saveData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(DeleteData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(itemlist.fulfilled, (state, action) => {
        state.itemlist = action.payload.itemlist
        state.SizeArray = action.payload.SizeArray
        state.params = action.payload.params
      })
      .addCase(Sizelist.fulfilled, (state, action) => {
        state.EditSizeList = action.payload.EditSizeList
        state.EditItemArray = action.payload.EditItemArray
        state.params = action.payload.params
      })
    }
  })

  export const {
    handleStatusFlag, clearstoreData
  } = ItemMasterSlice.actions
  
export default ItemMasterSlice.reducer

