// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../../utility/constants'


// SupplierOrder LIST
export const SupplierOrderReportlist = createAsyncThunk('SupplierOrder/SupplierOrderReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/purchasereport/supplier_PurchaseListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchasereport/supplier_PurchaseList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {
        params,
        SupplierOrderlist: response1.data.body.Supplierwiselist,
        CustomerTotalCount: response1.data.body.SupplierwiseCount
      }
     } else {
      return {
        params,
        SupplierOrderlist: [],
        CustomerTotalCount: 0
      }
     } 
  }
  })

  // ExcelSupplierOrder LIST
export const ExcelSupplierOrderReportlist = createAsyncThunk('SupplierOrder/ExcelSupplierOrderReportlist', async (params) => {
  const response = await axios.post(`${api.api_url}/purchasereport/supplier_PurchaseListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/purchasereport/supplier_PurchaseList`, params)
    if (response1 && response1.data && response1.data.body) { 
    return {
      params,
      ExcelSupplierOrderlist: response1.data.body.Supplierwiselist,
      CustomerTotalCount: response1.data.body.SupplierwiseCount,
      CompanyList:response1.data.body.CompanyArray
    }
   } else {
    return {
      params,
      ExcelSupplierOrderlist: [],
      CustomerTotalCount: 0
    }
   } 
}
})
  // Master List
  export const MasterReportlist = createAsyncThunk('SupplierOrder/MasterReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/purchasereport/reportPurchaseMasterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/purchasereport/reportPurchaseMasterList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        Supplier_Lists: response1.data.body.Supplier_Lists,
        Design_Lists: response1.data.body.Design_Lists,
        Color_Lists: response1.data.body.Color_Lists,
        Material_Lists: response1.data.body.Material_Lists
      }
     } else {
     } 
  }
  })
  
  //  GET Common LIST
export const commonData = createAsyncThunk('SupplierOrder/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      supplierList: response1.data.body.supplierList
    }
   } else {
   } 
}
})

export const SupplierOrderReportSlice = createSlice({
  name: 'SupplierOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    SupplierOrderlist: [],
    CustomerTotalCount:0,
    supplierList:[],
    statusFlag:0,
    ExcelSupplierOrderlist:[],
    CompanyArray:[],
    statuscode: 0,
    Supplier_Lists: [],
    Design_Lists: [],
    Color_Lists: [],
    Material_Lists: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state) => {
      state.ExcelSupplierOrderlist = []
    }
  },
  extraReducers: builder => {
    builder.addCase(SupplierOrderReportlist.fulfilled, (state, action) => {
      state.SupplierOrderlist = action.payload.SupplierOrderlist
      state.CustomerTotalCount = action.payload.CustomerTotalCount
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.supplierList = action.payload.supplierList
      state.params = action.payload.params
    })
    .addCase(ExcelSupplierOrderReportlist.fulfilled, (state, action) => {
      state.ExcelSupplierOrderlist = action.payload.ExcelSupplierOrderlist
      state.CustomerTotalCount = action.payload.CustomerTotalCount
      state.CompanyList = action.payload.CompanyList
      state.params = action.payload.params
    })
    .addCase(MasterReportlist.fulfilled, (state, action) => { 
      state.Supplier_Lists = action.payload.Supplier_Lists
      state.Design_Lists = action.payload.Design_Lists
      state.Color_Lists = action.payload.Color_Lists
      state.Material_Lists = action.payload.Material_Lists 
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = SupplierOrderReportSlice.actions
export default SupplierOrderReportSlice.reducer
