// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Itemgroup LIST
export const Ironmachinelist = createAsyncThunk('IronmachineMaster/Ironmachinelist', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/ironmachine/ironmachineListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/ironmachine/ironmachineList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Ironmachinelist:response1.data.body && response1.data.body.IronmachineArray ? response1.data.body.IronmachineArray : [],
      TotalIronmachine:response1.data.body && response1.data.body.TotalIronmachine ? response1.data.body.TotalIronmachine : 0
    }
   } else {
    return {
      params,
      Ironmachinelist:response1.data.body ? response1.data.body.IronmachineArray : [],
      TotalIronmachine:response1.data.body ? response1.data.body.TotalIronmachine : 0
    }
   } 
  } else {
    return {
      params,
      Ironmachinelist:response.data.body ? response.data.body.IronmachineArray : [],
      TotalIronmachine: response.data.body ? response.data.body.TotalIronmachine : 0
    }
   }
  } catch (error) {
    return {
      params,
      Ironmachinelist: [],
      TotalIronmachine:0
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('IronmachineMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/ironmachine/saveIronmachineJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/ironmachine/saveIronmachine`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('IronmachineMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/ironmachine/deleteIronmachineJwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/ironmachine/deleteIronmachine`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const IronmachineMasterSlice = createSlice({
  name: 'IronmachineMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Ironmachinelist:[],
    TotalIronmachine: 0,
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Ironmachinelist.fulfilled, (state, action) => {
      state.Ironmachinelist = action.payload.Ironmachinelist
      state.TotalIronmachine = action.payload.TotalIronmachine
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = IronmachineMasterSlice.actions
export default IronmachineMasterSlice.reducer
