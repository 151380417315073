// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST

export const commonData = createAsyncThunk('PerformaceReport/commonData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/employeePerformanceFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/report/employeePerformanceFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          allitemArray: response1.data.body && response1.data.body.allitemgroupArray ? response1.data.body.allitemgroupArray : [],
          EmployeeArray: response1.data.body && response1.data.body.EmployeeArray ? response1.data.body.EmployeeArray : [],
          JobTypeArray: response1.data.body && response1.data.body.alljobtypeArray ? response1.data.body.alljobtypeArray : [] 
        }
      } else {
        return {
          allitemArray: response1.data.body ? response1.data.body.allitemgroupArray : [],
          EmployeeArray: response1.data.body ? response1.data.body.EmployeeArray : [],
          JobTypeArray: response1.data.body ? response1.data.body.alljobtypeArray : []
        }
      }
    } else {
      return {
        allitemArray: response.data.body ? response.data.body.allitemgroupArray : [],
        EmployeeArray: response.data.body ? response.data.body.EmployeeArray : [],
        JobTypeArray: response.data.body ? response.data.body.alljobtypeArray : []
      }
    }
  } catch (error) {
    return {
      allitemArray: [],
      EmployeeArray: [],
      JobTypeArray: []
    }
  }
})

// PerformaceReport LIST

export const PerformaceReportlist = createAsyncThunk('PerformaceReport/PerformaceReportlist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/employeePerformanceReportListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/report/employeePerformanceReportList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          PerformaceReportlist: response1.data.body && response1.data.body.PerformanceArray ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body && response1.data.body.ItemcategoryeArray ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body && response1.data.body.Total_Array ? response1.data.body.Total_Array : []
          
        }
      } else {
        return {
          PerformaceReportlist: response1.data.body ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body ? response1.data.body.Total_Array : []
        }
      }
    } else {
      return {
        PerformaceReportlist: response.data.body ? response.data.body.PerformanceArray : [],
        ItemcategoryeList: response.data.body ? response.data.body.ItemcategoryeArray : [],
        TotalList: response.data.body ? response.data.body.Total_Array : []
      }
    }
  } catch (error) {
    return {
      PerformaceReportlist: [],
      ItemcategoryeList: [],
      TotalList: []
    }
  }
})

  //Employee Performace Report LIST
export const specificEmployeeList = createAsyncThunk('PerformaceReport/specificEmployeeList', async (params) => {
  const response = await axios.post(`${api.api_url}/report/specificEmployeeListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/report/specificEmployeeList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      SpecificEmployeeArray: response1.data.body.SpecificEmployeeArray
    }
   } else {
   } 
}
})

export const PerformaceReportSlice = createSlice({
  name: 'PerformaceReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    PerformaceReportlist:[],
    ItemcategoryeList:[],
    TotalList:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    allitemArray:[],
    EmployeeArray:[],
    JobTypeArray:[],
    SpecificEmployeeArray:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(PerformaceReportlist.fulfilled, (state, action) => {
      state.PerformaceReportlist = action.payload.PerformaceReportlist
      state.ItemcategoryeList = action.payload.ItemcategoryeList
      state.TotalList = action.payload.TotalList
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.allitemArray = action.payload.allitemArray
      state.EmployeeArray = action.payload.EmployeeArray
      state.JobTypeArray = action.payload.JobTypeArray
      state.params = action.payload.params
    })
    .addCase(specificEmployeeList.fulfilled, (state, action) => {
      state.SpecificEmployeeArray = action.payload.SpecificEmployeeArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = PerformaceReportSlice.actions
export default PerformaceReportSlice.reducer
