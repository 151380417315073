// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST
export const commonData = createAsyncThunk('JobCuttingSalaryProcess/commonData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EmployeeArray: response1.data.body.EmployeeArray,
        allDepartmentArray:response1.data.body.allDepartmentArray
      }
     } else {
      return {
        params,
        EmployeeArray: [],
        allDepartmentArray: []
      }
     } 
   }
  } catch (error) {
    return {
      params,
      EmployeeArray: [],
      allDepartmentArray: []
    }
  } 
})


// Employee LIST
export const Employeelist = createAsyncThunk('JobCuttingSalaryProcess/Employeelist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/ViewjobCuttingsjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/ViewjobCuttings`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        Employeelist: response1.data.body.Employeelist,
        TotalPieces: response1.data.body.TotalPieces,
        TotalAmount: response1.data.body.TotalAmount,
        TotalCount: response1.data.body.TotalCount
      }
     } else {
      return {
        params,
        Employeelist: [],
        TotalPieces: 0,
        TotalAmount: 0,
        TotalCount:0
      }
     } 
    } else {
      return {
        params,
        Employeelist: [],
        TotalPieces: 0,
        TotalAmount: 0,
        TotalCount:0
      }
    } 
  }  catch (error) {
    return {
      params,
      Employeelist: [],
      TotalPieces: 0,
      TotalAmount: 0,
      TotalCount:0
    }
  } 
})

// SalaryProcess LIST
export const SalaryProcesslist = createAsyncThunk('JobCuttingSalaryProcess/SalaryProcesslist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/jobCuttingProcessListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/jobCuttingProcessList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        ProcessList: response1.data.body.ProcessArray,
        CompanyList:response1.data.body.CompanyArray,
        TotalProcessList:response1.data.body.Total_Process
      }
     } else {
      return {
        ProcessList: [],
        CompanyList:[],
        TotalProcessList:0
      }
     } 
    }
  } catch (error) {
    return {
      ProcessList: [],
      CompanyList:[],
      TotalProcessList:0
    }
  } 
})

//saveData Api
export const saveData = createAsyncThunk('JobCuttingSalaryProcess/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/savejobCuttingProcessjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/savejobCuttingProcess`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   } 
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }    
  }
})


// Employee search
export const onchangeemployeeData = createAsyncThunk('JobCuttingSalaryProcess/onchangeemployeeData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/common/onchangeEmployeeDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/common/onchangeEmployeeData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          ListEmployee: response1.data.body.EmployeeList
        }
      } else {
      }
    }
  } catch (error) {
    return {
      ListEmployee: []
    }    
  }
})

// SalaryProcess Edit
export const editData = createAsyncThunk('JobCuttingSalaryProcess/editData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/editProcessjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/editProcess`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EditEmployeelist: response1.data.body.Employeelist
      }
     } else {
      return {
        params,
        EditEmployeelist: []
      }
     } 
    }
  } catch (error) {
    return {
      EditEmployeelist: []
    }    
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('JobCuttingSalaryProcess/deleteData', async params => { 
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/deleteProcessjwt`, params)  
    if (response && response.data && response.data.body && response.data.body.token) { 
      params['jwtToken'] = response.data.body.token
     const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/deleteProcess`, params)
     if (response1 && response1.data && response1.data.body) { 
      return { 
        statusFlag:response1.data.body.statusFlag, 
        message:response1.data.body.message
      }
     }
    } else { 
      return {  
        statusFlag:response.data.statusFlag,
        message:response.data.message
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }    
  }
})

// PayslipList search
export const PayslipList = createAsyncThunk('JobCuttingSalaryProcess/PayslipList', async params => {
  const response = await axios.post(`${api.api_url}/payroll/jobcutting/process/payslipListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/payroll/jobcutting/process/payslipList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        PayslipList: response1.data.body.PayslipArray
      }
    } else {
      return {
        PayslipList: []
      }
    }
  } else {
    return {
      PayslipList: []
    }
  }
})

export const JobCuttingSalaryProcessSlice = createSlice({
  name: 'JobCuttingSalaryProcess',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Employeelist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    EmployeeArray:[],
    allDepartmentArray:[],
    ProcessList:[],
    TotalCount:0,
    TotalAmount:0.00,
    TotalPieces:0,
    EditTotalCount:0,
    EditTotalAmount:0.00,
    EditTotalPieces:0,
    EditEmployeelist:[],
    CompanyList:[],
    PayslipList:[],
    TotalProcessList: 0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.Employeelist = action.payload.Employeelist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.PayslipList = action.payload.PayslipList
      state.EditEmployeelist = action.payload.EditEmployeelist
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(onchangeemployeeData.fulfilled, (state, action) => {
      state.ListEmployee = action.payload.ListEmployee
    })
    .addCase(PayslipList.fulfilled, (state, action) => {
      state.PayslipList = action.payload.PayslipList
    })
    .addCase(SalaryProcesslist.fulfilled, (state, action) => {
      state.ProcessList = action.payload.ProcessList
      state.CompanyList = action.payload.CompanyList
      state.params = action.payload.params
      state.TotalProcessList = action.payload.TotalProcessList 
    })
    .addCase(Employeelist.fulfilled, (state, action) => {
      state.Employeelist = action.payload.Employeelist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.params = action.payload.params
    })
    .addCase(editData.fulfilled, (state, action) => {
      state.EditEmployeelist = action.payload.EditEmployeelist
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.EmployeeArray = action.payload.EmployeeArray
      state.allDepartmentArray = action.payload.allDepartmentArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData
} = JobCuttingSalaryProcessSlice.actions
export default JobCuttingSalaryProcessSlice.reducer
