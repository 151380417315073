// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

 
// Item list search
export const onGetDesignList = createAsyncThunk('DispatchDataList/itemList', async params => {
  const response = await axios.post(`${api.api_url}/dispatched/designListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) { 
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/designList`, params)
    if (response1 && response1.data && response1.data.body) { 
      return { 
        DesignList: response1.data.body.DesignArray || [],
        CustomerList: response1.data.body.Customer_Array || [],
        CompanyList: response1.data.body.Company_Array || []
      }
    } else {
    }
  }
})
// Design View list search
export const onGetItemCustomerViewList = createAsyncThunk('DispatchDataList/itemCustomerWiseList', async params => {
  const response = await axios.post(`${api.api_url}/dispatched/itemCustomerWiseListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) { 
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/itemCustomerWiseList`, params)
    if (response1 && response1.data && response1.data.body) { 
      return { 
        ItemCustomerList: response1.data.body.itemArray || [],
        ItemCustomerListTotal: response1.data.body.dispatchTotal || 0,
        DispatchListStatusFlag: response1.data.body.statusFlag 
        // setItemwiseLoader(false)
      }
    } else {
    }
  }
})

// Design View list search
export const onGetCustomerViewList = createAsyncThunk('DispatchDataList/CustomerWiseList', async params => {
  const response = await axios.post(`${api.api_url}/dispatched/itemCustomerWiseListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) { 
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/itemCustomerWiseList`, params)
    if (response1 && response1.data && response1.data.body) { 
      return { 
        CustomerDesignList: response1.data.body.itemArray || [],
        CustomerDesignListTotal: response1.data.body.dispatchTotal || 0,
        DispatchListStatusFlag: response1.data.body.statusFlag 
      }
    } else {
    }
  }
})

// Save ItemWise Dispatch
export const saveItemWiseDispatchData = createAsyncThunk('DispatchDataList/saveItemWiseDispatch', async (params) => {
  try {
  const response = await axios.post(`${api.api_url}/dispatched/saveItemWiseDispatchJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/saveItemWiseDispatch`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
       message:response1.data.body.message,
       itemdispatchId :response1.data.body.itemdispatchId
     }
    } else {
      return {
       statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
       message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
       itemdispatchId :response1.data.body ? response1.data.body.itemdispatchId : 0
      }
     } 
   } else {
     return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
      itemdispatchId :response.data.body ? response.data.body.itemdispatchId  : 0
     }
    }
  } catch (error) {
    return {
      statusFlag:2,
      message:'Server not reachable.Please try again later',
      itemdispatchId: 0
    }    
  }
})

// Save ItemWise Dispatch
export const saveCustomerWiseDispatchData = createAsyncThunk('DispatchDataList/saveCustomerWiseDispatch', async (params) => {
  try {
  const response = await axios.post(`${api.api_url}/dispatched/saveCustomerWiseDispatchJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/saveCustomerWiseDispatch`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
       customerwisestatusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
       customerwisemessage:response1.data.body.message,
       customerdispatchId : response1.data.body.customerdispatchId
     }
    } else {
      return {
        customerwisestatusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        customerwisemessage:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later',
        customerdispatchId : response1.data.body ? response1.data.body.customerdispatchId : 0
      }
     } 
   } else {
     return {
       customerwisestatusFlag:response.data.body ? response1.data.body.statusFlag : 2,
       customerwisemessage:response.data.body ? response.data.body.message : 'Server not reachable.Please try again later',
       customerdispatchId : response.data.body ? response.data.body.customerdispatchId : 0
     }
    }
  } catch (error) {
    return {
      customerwisestatusFlag:2,
      customerwisemessage:'Server not reachable.Please try again later',
      customerdispatchId: 0
    }    
  }
})
export const PrintItemCustomerWiseDispatchlist = createAsyncThunk('DispatchListData/printItemCustomerWiseDispatchJwt', async (params) => {
  const response = await axios.post(`${api.api_url}/dispatched/printItemCustomerWiseDispatchJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatched/printItemCustomerWiseDispatch`, params)
    if (response1 && response1.data && response1.data.body) { 
    return { 
      print_single_dispatch_list: response1.data.body.dispatch_array || [],
      print_single_dispatch_company: response1.data.body.Company_Array || [],
      print_single_result: 'true'
    }
   } else {
    return { 
      print_single_dispatch_list: [],
      print_single_dispatch_company: [],
      print_single_result: 'false'
    }
   } 
}
})

export const DispacthSliceData = createSlice({
  name: 'DispatchDataList',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'',
    DesignList:[],
    ItemCustomerList : [],
    ItemCustomerListTotal: 0,
    CustomerList: [],
    CompanyList: [],
    CustomerDesignList: [],
    CustomerDesignListTotal: 0,
    customerwisestatusFlag:0,
    customerwisemessage:'',
    print_single_dispatch_list: [],
    print_single_dispatch_company: [],
    print_single_result: '',
    customerdispatchId:0,
    itemdispatchId:0,
    DispatchListStatusFlag: ''
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.itemdispatchId = action.payload.itemdispatchId
      state.customerwisestatusFlag = action.payload.customerwisestatusFlag
      state.customerwisemessage = action.payload.customerwisemessage
      state.customerdispatchId = action.payload.customerdispatchId
      state.DispatchListStatusFlag = action.payload.DispatchListStatusFlag
    },
    handleStoreList: (state, action) => {
      state.DesignList = action.payload.DesignList     
      state.CustomerList = action.payload.CustomerList
      state.CompanyList = action.payload.CompanyList
    },
    clearStoreData: (state, action) => {
      state.ItemCustomerList = action.payload.ItemCustomerList      
      state.CustomerDesignList = action.payload.CustomerDesignList      
      state.ItemCustomerListTotal = action.payload.ItemCustomerListTotal      
      state.CustomerDesignListTotal = action.payload.CustomerDesignListTotal      
      state.print_single_dispatch_list = action.payload.print_single_dispatch_list
      state.print_single_dispatch_company = action.payload.print_single_dispatch_company
      state.print_single_result = action.payload.print_single_result 
    } 
  },
  extraReducers: builder => {
    builder.addCase(onGetDesignList.fulfilled, (state, action) => {
      state.DesignList = action.payload.DesignList
      state.CustomerList = action.payload.CustomerList
      state.CompanyList = action.payload.CompanyList
    })  
    .addCase(onGetItemCustomerViewList.fulfilled, (state, action) => { 
      state.ItemCustomerList = action.payload.ItemCustomerList   
      state.ItemCustomerListTotal = action.payload.ItemCustomerListTotal      
      state.DispatchListStatusFlag = action.payload.DispatchListStatusFlag   
    })   
    .addCase(onGetCustomerViewList.fulfilled, (state, action) => {
        state.CustomerDesignList = action.payload.CustomerDesignList 
        state.DispatchListStatusFlag = action.payload.DispatchListStatusFlag
        state.CustomerDesignListTotal = action.payload.CustomerDesignListTotal      
    })
    .addCase(saveItemWiseDispatchData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.itemdispatchId = action.payload.itemdispatchId
    })
    .addCase(saveCustomerWiseDispatchData.fulfilled, (state, action) => {
      state.customerwisestatusFlag = action.payload.customerwisestatusFlag
      state.customerwisemessage = action.payload.customerwisemessage
      state.customerdispatchId = action.payload.customerdispatchId
    })    
    .addCase(PrintItemCustomerWiseDispatchlist.fulfilled, (state, action) => { 
      state.print_single_dispatch_list = action.payload.print_single_dispatch_list
      state.print_single_dispatch_company = action.payload.print_single_dispatch_company
      state.print_single_result = action.payload.print_single_result
    })
  }
})
export const {
  clearStoreData, handleStatusFlag, handleStoreList
} = DispacthSliceData.actions
export default DispacthSliceData.reducer
