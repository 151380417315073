// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//
export const getItemGroupList = createAsyncThunk('ItemsubgroupMaster/itemsubcategoryFilterListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemsubgroup/itemsubcategoryFilterListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/itemsubgroup/itemsubcategoryFilterList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      item_Group_List:response1.data.body && response1.data.body.ItemgroupArray ? response1.data.body.ItemgroupArray : [],
      item_Group_List_Message:response1.data.body.message
    }
   } else {
    return {
      item_Group_List:response1.data.body ? response1.data.body.ItemgroupArray : [],
      item_Group_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      item_Group_List:response.data.body ? response.data.body.ItemgroupArray : [],
      item_Group_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      item_Group_List: [],
      item_Group_List_Message:'Server not reachable.Please try again later'
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('ItemsubgroupMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemsubgroup/saveItemSubCategoryJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/itemsubgroup/saveItemSubCategory`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

// Itemgroup LIST

export const getItemSubGroupList = createAsyncThunk('ItemsubgroupMaster/itemGubCategoryListJwt', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemsubgroup/itemGubCategoryListJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/itemsubgroup/itemGubCategoryList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      item_Sub_Group_List:response1.data.body && response1.data.body.ItemsubgroupArray ? response1.data.body.ItemsubgroupArray : [],
      ItemgroupTotal: response1.data.body && response1.data.body.ItemgroupTotal ? response1.data.body.ItemgroupTotal : 0,
      item_Sub_Group_List_Message:response1.data.body.message
    }
   } else {
    return {
      item_Sub_Group_List:response1.data.body ? response1.data.body.ItemsubgroupArray : [],
      ItemgroupTotal:response1.data.body ? response1.data.body.ItemgroupTotal : 0,
      item_Sub_Group_List_Message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      item_Sub_Group_List:response.data.body ? response.data.body.ItemsubgroupArray : [],
      ItemgroupTotal:response.data.body ? response.data.body.ItemgroupTotal : 0,
      item_Sub_Group_List_Message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      item_Sub_Group_List: [],
      ItemgroupTotal: 0,
      item_Sub_Group_List_Message:'Server not reachable.Please try again later'
    }
  }
})

export const deleteData = createAsyncThunk('ItemsubgroupMaster/deleteData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/itemsubgroup/deleteItemSubgroupJwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params['jwtToken'] = response.data.body.token
   const response1 = await axios.post(`${api.api_url}/master/itemsubgroup/deleteItemSubgroup`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

export const ItemsubgroupMasterSlice = createSlice({
  name: 'ItemsubgroupMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Itemgrouplist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    item_Group_List: [],
    item_Group_List_Message: '',
    item_Sub_Group_List: [],
    item_Sub_Group_List_Message: '',
    ItemgroupTotal: 0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearStoreData: (state, action) => {
      state.item_Group_List = action.payload.item_Group_List
      state.item_Group_List_tMessage = action.payload.item_Group_List_Message
      state.item_Sub_Group_List = action.payload.item_Sub_Group_List
      state.item_Sub_Group_List_Message = action.payload.item_Sub_Group_List_Message
      state.ItemgroupTotal = action.payload.ItemgroupTotal      
    }    
  },
  extraReducers: builder => {
    builder.addCase(getItemGroupList.fulfilled, (state, action) => {
      state.item_Group_List = action.payload.item_Group_List
      state.item_Group_List_Message = action.payload.item_Group_List_Message
    }).addCase(getItemSubGroupList.fulfilled, (state, action) => {
      state.item_Sub_Group_List = action.payload.item_Sub_Group_List
      state.item_Sub_Group_List_Message = action.payload.item_Sub_Group_List_Message
      state.ItemgroupTotal = action.payload.ItemgroupTotal
    }).addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
  }
})
export const {
  handleStatusFlag, clearStoreData
} = ItemsubgroupMasterSlice.actions
export default ItemsubgroupMasterSlice.reducer
