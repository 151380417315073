// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../../utility/constants'


// CustomerOrder LIST
export const CustomerOrderReportlist = createAsyncThunk('CustomerOrder/CustomerOrderReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/customer_OrderListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/customer_OrderList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return {
        params,
        CustomerOrderlist: response1.data.body.Customerwiselist,
        CustomerTotalCount: response1.data.body.CustomerwiseCount
      }
     } else {
      return {
        params,
        CustomerOrderlist: [],
        CustomerTotalCount: 0
      }
     } 
  }
  })

  // ExcelCustomerOrder LIST
export const ExcelCustomerOrderReportlist = createAsyncThunk('CustomerOrder/ExcelCustomerOrderReportlist', async (params) => {
  const response = await axios.post(`${api.api_url}/orderreport/customer_OrderListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/orderreport/customer_OrderList`, params)
    if (response1 && response1.data && response1.data.body) { 
    return {
      params,
      ExcelCustomerOrderlist: response1.data.body.Customerwiselist,
      CustomerTotalCount: response1.data.body.CustomerwiseCount,
      CompanyList:response1.data.body.CompanyArray
    }
   } else {
    return {
      params,
      ExcelCustomerOrderlist: [],
      CustomerTotalCount: 0
    }
   } 
}
})
  // Master List
  export const MasterReportlist = createAsyncThunk('CustomerOrder/MasterReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        Customer_Lists: response1.data.body.Customer_Lists,
        Design_Lists: response1.data.body.Design_Lists,
        Color_Lists: response1.data.body.Color_Lists,
        Size_Lists: response1.data.body.Size_Lists
      }
     } else {
     } 
  }
  })
  
  //  GET Common LIST
export const commonData = createAsyncThunk('CustomerOrder/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      customerList: response1.data.body.customerList,
      agentList: response1.data.body.agentList
    }
   } else {
   } 
}
})

export const CustomerOrderReportSlice = createSlice({
  name: 'CustomerOrder',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    CustomerOrderlist: [],
    CustomerTotalCount:0,
    customerList:[],
    agentList:[],
    statusFlag:0,
    ExcelCustomerOrderlist:[],
    CompanyArray:[],
    statuscode: 0,
    Customer_Lists: [],
    Design_Lists: [],
    Color_Lists: [],
    Size_Lists: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    clearstoreData: (state) => {
      state.ExcelCustomerOrderlist = []
    }
  },
  extraReducers: builder => {
    builder.addCase(CustomerOrderReportlist.fulfilled, (state, action) => {
      state.CustomerOrderlist = action.payload.CustomerOrderlist
      state.CustomerTotalCount = action.payload.CustomerTotalCount
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.customerList = action.payload.customerList
      state.agentList = action.payload.agentList
      state.params = action.payload.params
    })
    .addCase(ExcelCustomerOrderReportlist.fulfilled, (state, action) => {
      state.ExcelCustomerOrderlist = action.payload.ExcelCustomerOrderlist
      state.CustomerTotalCount = action.payload.CustomerTotalCount
      state.CompanyList = action.payload.CompanyList
      state.params = action.payload.params
    })
    .addCase(MasterReportlist.fulfilled, (state, action) => { 
      state.Customer_Lists = action.payload.Customer_Lists
      state.Design_Lists = action.payload.Design_Lists
      state.Color_Lists = action.payload.Color_Lists
      state.Size_Lists = action.payload.Size_Lists 
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = CustomerOrderReportSlice.actions
export default CustomerOrderReportSlice.reducer
