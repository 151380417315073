// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


// PayoutReport LIST
export const PayoutReportlist = createAsyncThunk('PayoutReport/PayoutReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/report/payoutReportListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/report/payoutReportList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        PayoutReportlist: response1.data.body.PayoutArray
      }
     } else {
     } 
  }
  })

export const PayoutReportSlice = createSlice({
  name: 'PayoutReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    PayoutReportlist:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(PayoutReportlist.fulfilled, (state, action) => {
      state.PayoutReportlist = action.payload.PayoutReportlist
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = PayoutReportSlice.actions
export default PayoutReportSlice.reducer
