// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'


//  GET Common LIST

export const commonData = createAsyncThunk('JobCuttingPerformanceReport/commonData', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/employeeJobCuttingPerformanceFilterListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/report/employeeJobCuttingPerformanceFilterList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          allitemArray: response1.data.body && response1.data.body.allitemgroupArray ? response1.data.body.allitemgroupArray : [],
          EmployeeArray: response1.data.body && response1.data.body.EmployeeArray ? response1.data.body.EmployeeArray : []
        }
      } else {
        return {
          allitemArray: response1.data.body ? response1.data.body.allitemgroupArray : [],
          EmployeeArray: response1.data.body ? response1.data.body.EmployeeArray : []
        }
      }
    } else {
      return {
        allitemArray: response.data.body ? response.data.body.allitemgroupArray : [],
        EmployeeArray: response.data.body ? response.data.body.EmployeeArray : []
      }
    }
  } catch (error) {
    return {
      allitemArray: [],
      EmployeeArray: []
    }
  }
})

// PerformaceReport LIST

export const PerformaceReportlist = createAsyncThunk('JobCuttingPerformanceReport/PerformaceReportlist', async (params) => {
  try {
    const response = await axios.post(`${api.api_url}/report/employeeJobCuttingPerformanceReportListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/report/employeeJobCuttingPerformanceReportList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          PerformaceReportlist: response1.data.body && response1.data.body.PerformanceArray ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body && response1.data.body.ItemcategoryeArray ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body && response1.data.body.Total_Array ? response1.data.body.Total_Array : []
          
        }
      } else {
        return {
          PerformaceReportlist: response1.data.body ? response1.data.body.PerformanceArray : [],
          ItemcategoryeList: response1.data.body ? response1.data.body.ItemcategoryeArray : [],
          TotalList: response1.data.body ? response1.data.body.Total_Array : []
        }
      }
    } else {
      return {
        PerformaceReportlist: response.data.body ? response.data.body.PerformanceArray : [],
        ItemcategoryeList: response.data.body ? response.data.body.ItemcategoryeArray : [],
        TotalList: response.data.body ? response.data.body.Total_Array : []
      }
    }
  } catch (error) {
    return {
      PerformaceReportlist: [],
      ItemcategoryeList: [],
      TotalList: []
    }
  }
})

  //Employee Performace Report LIST

  export const specificEmployeeList = createAsyncThunk('JobCuttingPerformanceReport/specificEmployeeList', async (params) => {
    try {
      const response = await axios.post(`${api.api_url}/report/specificJobCuttingEmployeeListJwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
          params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/report/specificJobCuttingEmployeeList`, params)
        if (response1 && response1.data && response1.data.body) {
          return {
            params,
            SpecificEmployeeArray: response1.data.body && response1.data.body.SpecificEmployeeArray ? response1.data.body.SpecificEmployeeArray : []
          }
        } else {
          return {
            SpecificEmployeeArray: response1.data.body ? response1.data.body.SpecificEmployeeArray : []
          }
        }
      } else {
        return {
            SpecificEmployeeArray: response.data.body ? response.data.body.SpecificEmployeeArray : []
        }
      }
    } catch (error) {
      return {
        SpecificEmployeeArray: []
      }
    }
  })

export const JobCuttingPerformanceReportSlice = createSlice({
  name: 'JobCuttingPerformanceReport',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    PerformaceReportlist:[],
    ItemcategoryeList:[],
    TotalList:[],
    statuslist:[],
    statusFlag:0,
    statuscode:0,
    allitemArray:[],
    EmployeeArray:[],
    SpecificEmployeeArray:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(PerformaceReportlist.fulfilled, (state, action) => {
      state.PerformaceReportlist = action.payload.PerformaceReportlist
      state.ItemcategoryeList = action.payload.ItemcategoryeList
      state.TotalList = action.payload.TotalList
      state.params = action.payload.params
    })
    .addCase(commonData.fulfilled, (state, action) => {
      state.allitemArray = action.payload.allitemArray
      state.EmployeeArray = action.payload.EmployeeArray
      state.params = action.payload.params
    })
    .addCase(specificEmployeeList.fulfilled, (state, action) => {
      state.SpecificEmployeeArray = action.payload.SpecificEmployeeArray
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = JobCuttingPerformanceReportSlice.actions
export default JobCuttingPerformanceReportSlice.reducer
