// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Get FG data 
export const GetFGList = createAsyncThunk('FinishedGoodList/GetFGListjwt', async (params) => {
  const response = await axios.post(`${api.api_url}/dispatch/getFGListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params['jwtToken'] = response.data.body.token
    const response1 = await axios.post(`${api.api_url}/dispatch/getFGList`, params)
    if (response1 && response1.data && response1.data.body) {
     return {
      FGArray:response1.data.body.FGArray,
      FG_total: response1.data.body.FG_total || 0,
      FGWidget: response1.data.body.FGWidget || [],
      FGTotalArray: response1.data.body.FGTotalSet || []
     }
    } 
   } else {
    return {
      FGArray:[],
      FG_total: 0,
      FGWidget: [],
      FGTotalArray: []
     }
    }
})
  // Master List
  export const MasterReportlist = createAsyncThunk('FinishedGoodList/MasterReportlist', async (params) => {
    const response = await axios.post(`${api.api_url}/orderreport/reportMasterListjwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/orderreport/reportMasterList`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        Customer_Lists: response1.data.body.Customer_Lists,
        Design_Lists: response1.data.body.Design_Lists,
        Color_Lists: response1.data.body.Color_Lists,
        Size_Lists: response1.data.body.Size_Lists,
        ItemList : response1.data.body.itemArray,
        IronMachineList : response1.data.body.ironmachineArray
      }
     } else {
     } 
  }
  })
    //Print Dispatch
    export const PrintFGlist = createAsyncThunk('FinishedGoodList/printFGlist', async (params) => {
      const response = await axios.post(`${api.api_url}/dispatch/getFGListjwt`, params)
      if (response && response.data && response.data.body && response.data.body.token) {
        params['jwtToken'] = response.data.body.token
        const response1 = await axios.post(`${api.api_url}/dispatch/getFGList`, params)
        if (response1 && response1.data && response1.data.body) { 
        return { 
          print_FG_list: response1.data.body.FGArray || [],
          print_FG_company: response1.data.body.Company_Array || [],
          print_result: 'true',
          print_FG_Widget: response1.data.body.FGWidget || []
        }
       } else {
        return { 
          print_FG_list: [],
          print_FG_company: [],
          print_result: 'false',
          print_FG_Widget: []
        }
       } 
    }
    })
   //Check FG qty excess Dispatch qty
   export const checkFGItemDispatch = createAsyncThunk('FinishedGoodList/checkFGItemDispatch', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/checkFGItemDispatchJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/checkFGItemDispatch`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        Check_FG_list: response1.data.body.FGArray || [],
        Check_FG_message: response1.data.body.Message || ""
      }
     } else {
      return { 
        Check_FG_list: [],
        Check_FG_message: ""
      }
     } 
  }
  })
  //Check FG qty excess Dispatch qty
  export const DeleteFGItemJwt = createAsyncThunk('FinishedGoodList/DeleteFGItemJwt', async (params) => {
    const response = await axios.post(`${api.api_url}/dispatch/DeleteFGItemJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/dispatch/DeleteFGItem`, params)
      if (response1 && response1.data && response1.data.body) { 
      return { 
        FG_Delete_message: response1.data.body.message,
        FG_Delete_statusFlag: response1.data.body.statusFlag
      }
     } else {
      return { 
        FG_Delete_message: '',
        FG_Delete_statusFlag: 0
      }
     } 
  }
  })
  
export const BoxingData = createSlice({
  name: 'FinishedGoodList',
  initialState: { 
    params: {},
    statusFlag:0,
    message:'',
    ItemList:[],
    FGArray:[],
    Design_Lists:[],
    FG_total: 0,
    print_FG_list: [],
    print_FG_Widget: [],
    print_FG_company: [],
    print_result: '',
    Check_FG_list: [],
    Check_FG_message: '',
    FG_Delete_message: '',
    FG_Delete_statusFlag: 0,
    FGTotalArray: [],
    FGWidget: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.FG_Delete_message = action.payload.FG_Delete_message
      state.FG_Delete_statusFlag = action.payload.FG_Delete_statusFlag
    },
   
    clearstoreData: (state, action) => {
      state.FGArray = action.payload.FGArray    
      state.print_FG_list = action.payload.print_FG_list
      state.print_FG_Widget = action.payload.print_FG_Widget      
      state.print_result = action.payload.print_result
      state.print_FG_company = action.payload.print_FG_company
      state.Check_FG_list =  action.payload.Check_FG_list
      state.Check_FG_message = action.payload.Check_FG_message
    }
  },
  extraReducers: builder => {
    builder.addCase(GetFGList.fulfilled, (state, action) => {
      state.FGArray = action.payload.FGArray
      state.FG_total = action.payload.FG_total
      state.FGTotalArray = action.payload.FGTotalArray
      state.FGWidget = action.payload.FGWidget
    }).addCase(MasterReportlist.fulfilled, (state, action) => {
      state.Design_Lists = action.payload.Design_Lists
      state.ItemList = action.payload.ItemList
      state.IronMachineList = action.payload.IronMachineList
    }).addCase(PrintFGlist.fulfilled, (state, action) => { 
      state.print_FG_list = action.payload.print_FG_list
      state.print_FG_company = action.payload.print_FG_company 
      state.print_result = action.payload.print_result 
      state.print_FG_Widget = action.payload.print_FG_Widget      
    }).addCase(checkFGItemDispatch.fulfilled, (state, action) => { 
      state.Check_FG_list =  action.payload.Check_FG_list
      state.Check_FG_message = action.payload.Check_FG_message
    }).addCase(DeleteFGItemJwt.fulfilled, (state, action) => { 
      state.FG_Delete_statusFlag =  action.payload.FG_Delete_statusFlag
      state.FG_Delete_message = action.payload.FG_Delete_message
    })    
  }
})
export const {
  handleStatusFlag, clearstoreData
} = BoxingData.actions
export default BoxingData.reducer
