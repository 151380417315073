// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Design LIST
export const DesignMasterlist = createAsyncThunk('DesignMaster/DesignMasterlist', async (params) => {
  const response = await axios.post(`${api.api_url}/production/design/designListjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/design/designList`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      DesignMasterlist: response1.data.body.DesignArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('DesignMaster/saveData', async params => {
  const response = await axios.post(`${api.api_url}/production/design/saveDesignjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/design/saveDesign`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message,
      designid:response1.data.body.design_id
    }
   } 
  } else {
    return {
      statusFlag:response1.data.body.statusFlag,
      message:response1.data.body.message
    }
   }
})

//deleteData Api
export const deleteData = createAsyncThunk('DesignMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/production/design/deleteDesignjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/production/design/deleteDesign`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})

//Image Upload Api
export const uploadImage = createAsyncThunk('DesignMaster/uploadImage', async params => { 
   const response1 = await axios.post(`${api.api_url}/production/design/designPhotography`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      // statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
})

// Image LIST
export const Imagelist = createAsyncThunk('DesignMaster/Imagelist', async (params) => {
  const response = await axios.post(`${api.api_url}/production/design/editdesignjwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
    const response1 = await axios.post(`${api.api_url}/production/design/editdesign`, params)
    if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Imagelist:response1.data.body.ImageArray
    }
   } else {
   } 
}
})

export const DesignMasterSlice = createSlice({
  name: 'DesignMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    DesignMasterlist:[],
    statuslist:[],
    designid:0,
    statusFlag:0,
    statuscode:0,
    Imagelist:[]
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
     clearstoreData: (state, action) => {
        state.Imagelist = action.payload.Imagelist
      }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
      state.designid = action.payload.designid
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(DesignMasterlist.fulfilled, (state, action) => {
      state.DesignMasterlist = action.payload.DesignMasterlist
      state.params = action.payload.params
    })
    .addCase(Imagelist.fulfilled, (state, action) => {
      state.Imagelist = action.payload.Imagelist
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag, clearstoreData
} = DesignMasterSlice.actions
export default DesignMasterSlice.reducer
