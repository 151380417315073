// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

//  GET Common LIST

export const commonData = createAsyncThunk('EmbroiderSalaryPayout/commonData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/fetchcommonDataJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/fetchcommonData`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          EmbroiderArray: response1.data.body.EmbroiderArray,
          allDepartmentArray: response1.data.body.allDepartmentArray
        }
      } else {
        return {
          EmbroiderArray: [],
          allDepartmentArray: []
        }
      }
    } else {
      return {
        EmbroiderArray: [],
        allDepartmentArray: []
      }
    }
  } catch (error) {
    return {
      EmbroiderArray: [],
      allDepartmentArray: []
    }
  }
})

// Embroider Process LIST
export const EmbroiderProcesslist = createAsyncThunk('EmbroiderSalaryPayout/EmbroiderProcesslist', async (params) => {
  const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/embroiderProcessListJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = { jwtToken: response.data.body.token }
    const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/embroiderProcessList`, params)
    if (response1 && response1.data && response1.data.body) {
      return {
        params,
        EmbroiderProcesslist: response1.data.body.EmbroiderProcesslist,
        TotalPieces: response1.data.body.TotalPieces,
        TotalAmount: response1.data.body.TotalAmount,
        TotalCount: response1.data.body.TotalCount
      }
    } else {
      return {
        params,
        EmbroiderProcesslist: []
      }
    }
  }
})


export const SalaryPayoutlist = createAsyncThunk('EmbroiderSalaryPayout/SalaryPayoutlist', async params => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/jobEmbroiderPayoutListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/jobEmbroiderPayoutList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          Payoutlist: response1.data.body.PayoutArray,
          TotalPayout: response1.data.body.TotalResult
        }
      } else {
        return {
          params,
          Payoutlist: [],
          TotalPayout: 0
        }
      }
    } else {
      return {
        params,
        Payoutlist: [],
        TotalPayout: 0
      }
    }
  } catch (error) {
    return {
      params,
      Payoutlist: [],
      TotalPayout: 0
    }
  }
})

//saveData Api
export const saveData = createAsyncThunk('EmbroiderSalaryPayout/saveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/savePayoutJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params['jwtToken'] = response.data.body.token
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/savePayout`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          statusFlag: response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
          message: response1.data.body.message
        }
      } else {
        return {
          statusFlag: response1.data.body ? response1.data.body.statusFlag : 2,
          message: response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
        }
      }
    } else {
      return {
        statusFlag: response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
    }
  } catch (error) {
    return {
      statusFlag: 2,
      message: 'Server not reachable.Please try again later'
    }
  }
})

// Edit SalaryPayout
export const editData = createAsyncThunk('EmbroiderSalaryPayout/editData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/editPayoutJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/editPayout`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          params,
          EditEmbroiderProcesslist: response1.data.body.EmbroiderProcesslist,
          CompanyData: response1.data.body.CompanyData
        }
      } else {
        return {
          params,
          EditEmbroiderProcesslist: [],
          CompanyData: []
        }
      }
    } else {
      return {
        params,
        EditEmbroiderProcesslist: [],
        CompanyData: []
      }
    }
  } catch (error) {
    return {
      params,
      EditEmbroiderProcesslist: [],
      CompanyData: []
    }
  }
})

// PayOutslipList search
export const PayOutslipList = createAsyncThunk('EmbroiderSalaryPayout/PayoutSlip', async params => {
  try {
    const response = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/payoutslipListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = { jwtToken: response.data.body.token }
      const response1 = await axios.post(`${api.api_url}/payroll/jobEmbroider/payout/payoutslipList`, params)
      if (response1 && response1.data && response1.data.body) {
        return {
          PayoutslipList: response1.data.body.PayslipArray,
          CompanyList: response1.data.body.CompanyArray
        }
      } else {
        return {
          PayoutslipList: [],
          CompanyList: []
        }
      }
    } else {
      return {
        PayoutslipList: [],
        CompanyList: []
      }
    }
  } catch (error) {
    return {
      PayoutslipList: [],
      CompanyList: []
    }
  }
})

export const EmbroiderSalaryPayoutSlice = createSlice({
  name: 'EmbroiderSalaryPayout',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    RateCartlist: [],
    statuslist: [],
    statusFlag: 0,
    statuscode: 0,
    EmbroiderArray: [],
    allDepartmentArray: [],
    EditEmbroiderProcesslist: [],
    CompanyData: [],
    ListEmbroider: [],
    EmbroiderProcesslist: [],
    TotalCount: 0,
    TotalAmount: 0.00,
    TotalPieces: 0,
    Payoutlist: [],
    TotalPayout: 0,
    PayoutslipList: [],
    CompanyList: []
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    },
    handleedit: (state, action) => {
      state.editaction = action.payload
    },
    clearstoreData: (state, action) => {
      state.EmbroiderProcesslist = action.payload.EmbroiderProcesslist
      state.EditEmbroiderProcesslist = action.payload.EditEmbroiderProcesslist
      state.CompanyData = action.payload.CompanyData
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
      state.PayoutslipList = action.payload.PayoutslipList
    },
    clearpayrollstoreData: (state, action) => {
      state.EmbroiderProcesslist = action.payload.EmbroiderProcesslist
      state.TotalCount = action.payload.TotalCount
      state.TotalAmount = action.payload.TotalAmount
      state.TotalPieces = action.payload.TotalPieces
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
      .addCase(PayOutslipList.fulfilled, (state, action) => {
        state.PayoutslipList = action.payload.PayoutslipList
        state.CompanyList = action.payload.CompanyList
      })
      .addCase(SalaryPayoutlist.fulfilled, (state, action) => {
        state.Payoutlist = action.payload.Payoutlist
        state.TotalPayout = action.payload.TotalPayout
        state.params = action.payload.params
      })
      .addCase(editData.fulfilled, (state, action) => {
        state.EditEmbroiderProcesslist = action.payload.EditEmbroiderProcesslist
        state.CompanyData = action.payload.CompanyData
        state.params = action.payload.params
      })
      .addCase(commonData.fulfilled, (state, action) => {
        state.EmbroiderArray = action.payload.EmbroiderArray
        state.allDepartmentArray = action.payload.allDepartmentArray
        state.params = action.payload.params
      })
      .addCase(EmbroiderProcesslist.fulfilled, (state, action) => {
        state.EmbroiderProcesslist = action.payload.EmbroiderProcesslist
        state.TotalCount = action.payload.TotalCount
        state.TotalAmount = action.payload.TotalAmount
        state.TotalPieces = action.payload.TotalPieces
      })
  }
})
export const {
  handleStatusFlag, handleedit, clearstoreData, clearpayrollstoreData
} = EmbroiderSalaryPayoutSlice.actions
export default EmbroiderSalaryPayoutSlice.reducer
