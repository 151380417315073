// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants' 

//  GET Common LIST
export const commonData = createAsyncThunk('CompanyMaster/commonData', async (params) => {
  const response = await axios.post(`${api.api_url}/common/fetchcommonDataJwt`, params)
  if (response && response.data && response.data.body && response.data.body.token) {
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/common/fetchcommonData`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      StateArray: response1.data.body.StateArray
    }
   } else {
   } 
}
})

//saveData Api
export const saveData = createAsyncThunk('CompanyMaster/saveData', async params => {
  try {
    const response = await axios.post(`${api.api_url}/master/company/saveCompanyJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/master/company/saveCompany`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
        message:response1.data.body.message
      }
     } else {
      return {
        statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
        message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
      }
     }  
    } else {
      return {
        statusFlag:response.data.body ? response.data.body.statusFlag : 2,
        message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
      }
     }
    } catch (error) {
      return {
        statusFlag: 2,
        message:'Server not reachable.Please try again later'
      }
    }
  })

  
// Company LIST
export const Companylist = createAsyncThunk('CompanyMaster/Companylist', async (params) => {
    const response = await axios.post(`${api.api_url}/master/company/companyListJwt`, params)
    if (response && response.data && response.data.body && response.data.body.token) {
      params = {jwtToken:response.data.body.token}
      const response1 = await axios.post(`${api.api_url}/master/company/companyList`, params)
      if (response1 && response1.data && response1.data.body) {
      return {
        params,
        Companylist: response1.data.body.CompanyArray
      }
     } else {
     } 
  }
  })

  //DeleteData Api
export const DeleteData = createAsyncThunk('CompanyMaster/DeleteData', async params => {
    const response = await axios.post(`${api.api_url}/master/company/deleteCompanyJwt`, params)
     if (response && response.data && response.data.body && response.data.body.token) {
     params = {jwtToken:response.data.body.token}
     const response1 = await axios.post(`${api.api_url}/master/company/deleteCompany`, params)
     if (response1 && response1.data && response1.data.body) {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     } 
    } else {
      return {
        statusFlag:response1.data.body.statusFlag,
        message:response1.data.body.message
      }
     }
  })

export const CompanyMasterSlice = createSlice({
    name: 'CompanyMaster',
    initialState: {
      data: [],
      message:'',
      statuslist:[],
      params: {},
      statusFlag:0,
      StateArray:[],
      Companylist:[]
    },     
    reducers: {
      handleStatusFlag: (state, action) => {
        state.statusFlag = action.payload.statusFlag
      }
    },
    extraReducers: builder => {
      builder.addCase(commonData.fulfilled, (state, action) => {
        state.StateArray = action.payload.StateArray
        state.params = action.payload.params
      })
      .addCase(saveData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(DeleteData.fulfilled, (state, action) => {
        state.statusFlag = action.payload.statusFlag
        state.message = action.payload.message
      })
      .addCase(Companylist.fulfilled, (state, action) => {
        state.Companylist = action.payload.Companylist
        state.params = action.payload.params
      })
    }
  })

  export const {
    handleStatusFlag
  } = CompanyMasterSlice.actions
  
export default CompanyMasterSlice.reducer

