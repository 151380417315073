// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { api } from '../../../../utility/constants'

// Brand LIST

export const Brandlist = createAsyncThunk('BrandMaster/Brandlist', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/brand/brandListjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/brand/brandList`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      params,
      Brandlist:response1.data.body && response1.data.body.BrandArray ? response1.data.body.BrandArray : [],
      TotalBrand:response1.data.body && response1.data.body.TotalBrand ? response1.data.body.TotalBrand : 0
    }
   } else {
    return {
      params,
      Brandlist:response1.data.body ? response1.data.body.BrandArray : [],
      TotalBrand:response1.data.body ? response1.data.body.TotalBrand : 0
    }
   }  
  } else {
    return {
      params,
      Brandlist:response.data.body ? response.data.body.BrandArray : [],
      TotalBrand: response.data.body ? response.data.body.TotalBrand : 0
    }
   }
  } catch (error) {
    return {
      params,
      Brandlist: [],
      TotalBrand:0
    }
  }
})
//saveData Api
export const saveData = createAsyncThunk('BrandMaster/saveData', async params => {
  try {
  const response = await axios.post(`${api.api_url}/master/brand/saveBrandjwt`, params)
   if (response && response.data && response.data.body && response.data.body.token) {
   params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/brand/saveBrand`, params)
   if (response1 && response1.data && response1.data.body) {
    return {
      statusFlag:response1.data.body && response1.data.body.statusFlag ? response1.data.body.statusFlag : 2,
      message:response1.data.body.message
    }
   } else {
    return {
      statusFlag:response1.data.body ? response1.data.body.statusFlag : 2,
      message:response1.data.body ? response1.data.body.message : 'Server not reachable.Please try again later'
    }
   }  
  } else {
    return {
      statusFlag:response.data.body ? response.data.body.statusFlag : 2,
      message: response.data.body ? response.data.body.message : 'Server not reachable.Please try again later'
    }
   }
  } catch (error) {
    return {
      statusFlag: 2,
      message:'Server not reachable.Please try again later'
    }
  }
})

//deleteData Api
export const deleteData = createAsyncThunk('BrandMaster/deleteData', async params => { 
  const response = await axios.post(`${api.api_url}/master/brand/deleteBrandjwt`, params)  
  if (response && response.data && response.data.body && response.data.body.token) { 
    params = {jwtToken:response.data.body.token}
   const response1 = await axios.post(`${api.api_url}/master/brand/deleteBrand`, params)
   if (response1 && response1.data && response1.data.body) { 
    return { 
      statusFlag:response1.data.body.statusFlag, 
      message:response1.data.body.message
    }
   }
  } else { 
    return {  
      statusFlag:response.data.statusFlag,
      message:response.data.message
    }
   }
})
export const BrandMasterSlice = createSlice({
  name: 'BrandMaster',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    Brandlist:[],
    TotalBrand:0,
    statuslist:[],
    statusFlag:0,
    statuscode:0
  },
  reducers: {
    handleStatusFlag: (state, action) => {
      state.statusFlag = action.payload.statusFlag
    }
  },
  extraReducers: builder => {
    builder.addCase(saveData.fulfilled, (state, action) => {
      state.statusFlag = action.payload.statusFlag
      state.message = action.payload.message
    })
    .addCase(deleteData.fulfilled, (state, action) => {
      state.message = action.payload.message
     state.statusFlag = action.payload.statusFlag
    })
    .addCase(Brandlist.fulfilled, (state, action) => {
      state.Brandlist = action.payload.Brandlist
      state.TotalBrand = action.payload.TotalBrand
      state.params = action.payload.params
    })
  }
})
export const {
  handleStatusFlag
} = BrandMasterSlice.actions
export default BrandMasterSlice.reducer
